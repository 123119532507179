import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text"
}
const _hoisted_2 = ["src", "alt"]

import { watchEffect, ref } from '../../../../adapter-vue';
import { CUSTOM_BASIC_EMOJI_URL, CUSTOM_BASIC_EMOJI_URL_MAPPING } from '../../emoji-config';
import { isPC } from '../../../../utils/env';
interface IProps {
  content: Record<string, any>;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'message-text',
  props: {
    content: { default: () => ({}) }
  },
  setup(__props: any) {

const props = __props;
const data = ref();
watchEffect(() => {
  data.value = props.content;
  data.value.text?.forEach((item: { name: string; text?: string; src?: string; type?: string; emojiKey?: string }) => {
    if (item.name === 'img' && item?.type === 'custom') {
      if (!CUSTOM_BASIC_EMOJI_URL) {
        console.warn('CUSTOM_BASIC_EMOJI_URL is required for custom emoji, please check your CUSTOM_BASIC_EMOJI_URL.');
      } else if (!item.emojiKey || !CUSTOM_BASIC_EMOJI_URL_MAPPING[item.emojiKey]) {
        console.warn('emojiKey is required for custom emoji, please check your emojiKey.');
      } else {
        item.src = CUSTOM_BASIC_EMOJI_URL + CUSTOM_BASIC_EMOJI_URL_MAPPING[item.emojiKey];
      }
    }
  });
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['message-text-container', _unref(isPC) && 'text-select'])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(data).text, (item, index) => {
      return (_openBlock(), _createElementBlock("span", { key: index }, [
        (item.name === 'text')
          ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(item.text), 1))
          : (_openBlock(), _createElementBlock("img", {
              key: 1,
              class: "emoji",
              src: item.src,
              alt: item.emojiKey
            }, null, 8, _hoisted_2))
      ]))
    }), 128))
  ], 2))
}
}

})