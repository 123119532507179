import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { onMounted, onUnmounted, ref, watch, getCurrentInstance, withDefaults } from '../../../adapter-vue';
import { TUITranslateService } from '@tencentcloud/chat-uikit-engine';
import { isUniFrameWork } from '../../../utils/env';

interface IProps {
  isFetching: boolean;
  isTerminateObserve?: boolean;
}

interface IEmits {
  (e: 'onExposed'): void;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
    isFetching: { type: Boolean, default: false },
    isTerminateObserve: { type: Boolean, default: false }
  },
  emits: ["onExposed"],
  setup(__props: any, { emit: __emit }) {

const emits = __emit;
const props = __props;

let observer: unknown = null;
const selfDomRef = ref();
const thisInstance = getCurrentInstance()?.proxy || getCurrentInstance();

onMounted(() => {
  if (props.isTerminateObserve) {
    return;
  }
  if (!isUniFrameWork) {
    observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        emits('onExposed');
      }
    }, {
      threshold: 1.0,
    });
    if (selfDomRef.value) {
      (observer as IntersectionObserver).observe(selfDomRef.value);
    }
  } else {
    observer = uni.createIntersectionObserver(thisInstance).relativeToViewport();
    (observer as any).observe('.fetch-more-block', () => {
      emits('onExposed');
    });
  }
});

onUnmounted(() => {
  if (observer) {
    (observer as IntersectionObserver).disconnect();
    observer = null;
  }
});

watch(() => props.isTerminateObserve, (isTerminateObserve: boolean) => {
  if (!observer) {
    return;
  }
  if (isTerminateObserve) {
    (observer as IntersectionObserver).disconnect();
  } else if (selfDomRef.value) {
    (observer as IntersectionObserver).disconnect();
    if (!isUniFrameWork) {
      (observer as IntersectionObserver).observe(selfDomRef.value);
    } else {
      (observer as any).observe('.fetch-more-block', () => {
        emits('onExposed');
      });
    }
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    ref_key: "selfDomRef",
    ref: selfDomRef,
    class: "fetch-more-block"
  }, [
    (props.isFetching)
      ? _renderSlot(_ctx.$slots, "fetching", { key: 0 }, () => [
          _createElementVNode("div", null, _toDisplayString(_unref(TUITranslateService).t("TUIChat.正在加载")), 1)
        ])
      : _renderSlot(_ctx.$slots, "fetchEnd", { key: 1 }, () => [
          _createElementVNode("div", null, _toDisplayString(_unref(TUITranslateService).t("TUIChat.加载结束")), 1)
        ])
  ], 512))
}
}

})