import { defineComponent as _defineComponent } from 'vue'
import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]


export default /*@__PURE__*/_defineComponent({
  __name: 'image-item',
  props: {
  zoom: {
    type: Number,
    default: 1,
  },
  rotate: {
    type: Number,
    default: 0,
  },
  src: {
    type: String,
    default: '',
  },
},
  setup(__props) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("img", {
    class: "image-preview",
    style: _normalizeStyle({
      transform: `scale(${props.zoom}) rotate(${props.rotate}deg)`,
    }),
    src: props.src
  }, null, 12, _hoisted_1))
}
}

})