import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { ref } from '../../../adapter-vue';
import {
  TUITranslateService,
} from '@tencentcloud/chat-uikit-engine';
import Icon from '../../common/Icon.vue';
import ForwardEachIcon from '../../../assets/icon/forward-each.svg';
import ForwardMergeIcon from '../../../assets/icon/forward-merge.svg';
import AddIcon from '../../../assets/icon/add-circle.svg';
import { isMobile } from '../../../utils/env';

interface IEmits {
  (key: 'oneByOneForwardMessage'): void;
  (key: 'mergeForwardMessage'): void;
  (key: 'toggleMultipleSelectMode'): void;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  emits: ["oneByOneForwardMessage", "mergeForwardMessage", "toggleMultipleSelectMode"],
  setup(__props, { emit: __emit }) {

const emits = __emit;

const iconSize = ref(isMobile ? '25px' : '30px');

function oneByOneForwardMessage() {
  emits('oneByOneForwardMessage');
}

function mergeForwardMessage() {
  emits('mergeForwardMessage');
}

function cancelMultipleSelect() {
  emits('toggleMultipleSelectMode');
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
      'mulitple-select-panel': true,
      'mulitple-select-panel-mobile': _unref(isMobile),
    })
  }, [
    _createElementVNode("div", {
      class: "forward-button",
      onClick: oneByOneForwardMessage
    }, [
      _createVNode(Icon, {
        file: _unref(ForwardEachIcon),
        size: _unref(iconSize)
      }, null, 8, ["file", "size"]),
      _createElementVNode("span", {
        class: _normalizeClass({
          'forward-button-text': true,
          'forward-button-text-mobile': _unref(isMobile),
        })
      }, _toDisplayString(_unref(TUITranslateService).t('TUIChat.逐条转发')), 3)
    ]),
    _createElementVNode("div", {
      class: "forward-button",
      onClick: mergeForwardMessage
    }, [
      _createVNode(Icon, {
        file: _unref(ForwardMergeIcon),
        size: _unref(iconSize)
      }, null, 8, ["file", "size"]),
      _createElementVNode("span", {
        class: _normalizeClass({
          'forward-button-text': true,
          'forward-button-text-mobile': _unref(isMobile),
        })
      }, _toDisplayString(_unref(TUITranslateService).t('TUIChat.合并转发')), 3)
    ]),
    _createElementVNode("div", {
      class: "forward-button",
      onClick: cancelMultipleSelect
    }, [
      _createVNode(Icon, {
        class: "cancel-button-icon",
        file: _unref(AddIcon),
        size: _unref(iconSize)
      }, null, 8, ["file", "size"]),
      _createElementVNode("span", {
        class: _normalizeClass({
          'forward-button-text': true,
          'forward-button-text-mobile': _unref(isMobile),
        })
      }, _toDisplayString(_unref(TUITranslateService).t('TUIChat.取消')), 3)
    ])
  ], 2))
}
}

})