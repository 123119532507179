import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["onClick"]

import {
  TUITranslateService,
  TUIStore,
  StoreName,
  IConversationModel,
  SendMessageParams,
  TUIChatService,
} from '@tencentcloud/chat-uikit-engine';
import { ref } from '../../../../adapter-vue';
import ToolbarItemContainer from '../toolbar-item-container/index.vue';
import wordsIconLight from '../../../../assets/icon/words-light.svg';
import wordsIconDark from '../../../../assets/icon/words-dark.svg';
import { wordsList } from '../../utils/wordsList';
import { isEnabledMessageReadReceiptGlobal } from '../../utils/utils';
import { isPC, isUniFrameWork } from '../../../../utils/env';
import TUIChatConfig from '../../config';


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  emits: ['onDialogPopupShowOrHide'],
  setup(__props, { emit: __emit }) {

const wordsIcon = TUIChatConfig.getTheme() === 'dark' ? wordsIconDark : wordsIconLight;
const emits = __emit;
const currentConversation = ref<IConversationModel>();
const container = ref();

TUIStore.watch(StoreName.CONV, {
  currentConversation: (conversation: IConversationModel) => {
    currentConversation.value = conversation;
  },
});

const selectWord = (item: any) => {
  const options = {
    to:
      currentConversation?.value?.groupProfile?.groupID
      || currentConversation?.value?.userProfile?.userID,
    conversationType: currentConversation?.value?.type,
    payload: {
      text: TUITranslateService.t(`Words.${item.value}`),
    },
    needReadReceipt: isEnabledMessageReadReceiptGlobal(),
  } as SendMessageParams;
  TUIChatService.sendTextMessage(options);
  // close dialog after submit evaluate
  container?.value?.toggleDialogDisplay(false);
};

const closeDialog = () => {
  container?.value?.toggleDialogDisplay(false);
};

const onDialogShow = () => {
  emits('onDialogPopupShowOrHide', true);
};

const onDialogClose = () => {
  emits('onDialogPopupShowOrHide', false);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(ToolbarItemContainer, {
    ref_key: "container",
    ref: container,
    iconFile: _unref(wordsIcon),
    title: "常用语",
    needBottomPopup: true,
    iconWidth: _unref(isUniFrameWork) ? '26px' : '20px',
    iconHeight: _unref(isUniFrameWork) ? '26px' : '20px',
    onOnDialogShow: onDialogShow,
    onOnDialogClose: onDialogClose
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(['words', !_unref(isPC) && 'words-h5'])
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(['words-header', !_unref(isPC) && 'words-h5-header'])
        }, [
          _createElementVNode("span", {
            class: _normalizeClass(['words-header-title', !_unref(isPC) && 'words-h5-header-title'])
          }, _toDisplayString(_unref(TUITranslateService).t("Words.常用语-快捷回复工具")), 3),
          (!_unref(isPC))
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                class: _normalizeClass(['words-header-close', !_unref(isPC) && 'words-h5-header-close']),
                onClick: closeDialog
              }, " 关闭 ", 2))
            : _createCommentVNode("", true)
        ], 2),
        _createElementVNode("ul", {
          class: _normalizeClass(['words-list', !_unref(isPC) && 'words-h5-list'])
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(wordsList), (item, index) => {
            return (_openBlock(), _createElementBlock("li", {
              key: index,
              class: _normalizeClass(['words-list-item', !_unref(isPC) && 'words-h5-list-item']),
              onClick: ($event: any) => (selectWord(item))
            }, _toDisplayString(_unref(TUITranslateService).t(`Words.${item.value}`)), 11, _hoisted_1))
          }), 128))
        ], 2)
      ], 2)
    ]),
    _: 1
  }, 8, ["iconFile", "iconWidth", "iconHeight"]))
}
}

})