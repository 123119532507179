import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  ref: "dialog",
  class: "member-list"
}
const _hoisted_2 = {
  key: 0,
  class: "member-list-title"
}
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "member-list-box" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "member-list-box-body-name" }

import TUIChatEngine, {
  TUIStore,
  StoreName,
  TUIGroupService,
  TUITranslateService,
} from '@tencentcloud/chat-uikit-engine';
import { TUIGlobal } from '@tencentcloud/universal-api';
import { ref, watch } from '../../../../adapter-vue';
import { isPC, isH5 } from '../../../../utils/env';
import BottomPopup from '../../../common/BottomPopup/index.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  emits: ['onAtListOpen', 'insertAt'],
  setup(__props, { expose: __expose, emit: __emit }) {

const emits = __emit;

const MessageInputAt = ref();
const memberListItems = ref();

const showAtList = ref(false);
const memberList = ref<Array<any>>();
const allMemberList = ref<Array<any>>();
const showMemberList = ref<Array<any>>();
const isGroup = ref(false);
const position = ref({
  left: 0,
  top: 0,
});
const selectedIndex = ref(0);
const currentConversationID = ref('');

const all = {
  userID: TUIChatEngine.TYPES.MSG_AT_ALL,
  nick: '所有人',
  isAll: true,
  avatar: 'https://web.sdk.qcloud.com/im/assets/images/at.svg',
};

TUIStore.watch(StoreName.CONV, {
  currentConversationID: (id: string) => {
    if (id !== currentConversationID.value) {
      currentConversationID.value = id;
      memberList.value = [];
      allMemberList.value = [];
      showMemberList.value = [];
      isGroup.value = false;
      TUIStore.update(StoreName.CUSTOM, 'memberList', memberList.value);
      if (currentConversationID?.value?.startsWith('GROUP')) {
        isGroup.value = true;
        const groupID = currentConversationID?.value?.substring(5);
        TUIGroupService.switchGroup(groupID);
      } else {
        TUIGroupService.switchGroup('');
      }
    }
  },
});

TUIStore.watch(StoreName.GRP, {
  currentGroupMemberList: (list: Array<any>) => {
    memberList.value = list;
    allMemberList.value = [all, ...memberList.value];
    showMemberList.value = allMemberList.value;
    TUIStore.update(StoreName.CUSTOM, 'memberList', memberList.value);
  },
});

const toggleAtList = (show: boolean) => {
  if (!isGroup.value) {
    return;
  }
  showAtList.value = show;
  if (showAtList.value) {
    emits('onAtListOpen');
  }
};
const handleAtListPosition = (positionData: { top: number; left: number }) => {
  position.value = positionData;
};
const setCurrentSelectIndex = (index: any) => {
  selectedIndex.value = index;
  memberListItems.value?.[selectedIndex.value]?.scrollIntoView(false);
};
const setShowMemberList = (list: any) => {
  showMemberList.value = list;
};

TUIGlobal.toggleAtList = toggleAtList;
TUIGlobal.handleAtListPosition = handleAtListPosition;
TUIGlobal.setCurrentSelectIndex = setCurrentSelectIndex;
TUIGlobal.setShowMemberList = setShowMemberList;

__expose({
  toggleAtList,
});

watch(
  () => [position.value, MessageInputAt?.value],
  () => {
    if (isH5 || !MessageInputAt?.value || !MessageInputAt?.value?.style) {
      return;
    }
    MessageInputAt.value.style.left = position.value.left + 'px';
    MessageInputAt.value.style.top
      = position.value.top - MessageInputAt.value.clientHeight + 'px';
  },
);

const closeAt = () => {
  showAtList.value = false;
  showMemberList.value = allMemberList.value;
  position.value = {
    left: 0,
    top: 0,
  };
};

const selectItem = (index: number) => {
  if (isPC && TUIGlobal.selectItem) {
    TUIGlobal.selectItem(index);
  } else {
    if (showMemberList?.value?.length) {
      const item = showMemberList?.value[index];
      emits('insertAt', {
        id: (item as any)?.userID,
        label: (item as any)?.nick || (item as any)?.userID,
      });
    }
  }
  closeAt();
};

const handleMemberAvatar = (item: any) => {
  return (
    (item as any)?.avatar
    || 'https://web.sdk.qcloud.com/component/TUIKit/assets/avatar_21.png'
  );
};

const handleMemberName = (item: any) => {
  return (item as any)?.nick ? (item as any)?.nick : (item as any)?.userID;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(BottomPopup, {
    show: _unref(showAtList),
    onOnClose: closeAt
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        ref_key: "MessageInputAt",
        ref: MessageInputAt,
        class: _normalizeClass([_unref(isPC) ? 'message-input-at' : 'message-input-at-h5'])
      }, [
        _createElementVNode("div", _hoisted_1, [
          (!_unref(isPC))
            ? (_openBlock(), _createElementBlock("header", _hoisted_2, [
                _createElementVNode("span", _hoisted_3, _toDisplayString(_unref(TUITranslateService).t("TUIChat.选择提醒的人")), 1)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("ul", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(showMemberList), (item, index) => {
              return (_openBlock(), _createElementBlock("li", {
                key: index,
                ref_for: true,
                ref_key: "memberListItems",
                ref: memberListItems,
                class: _normalizeClass(["member-list-box-body", [index === _unref(selectedIndex) && 'selected']]),
                onClick: ($event: any) => (selectItem(index))
              }, [
                _createElementVNode("img", {
                  class: "member-list-box-body-avatar",
                  src: handleMemberAvatar(item)
                }, null, 8, _hoisted_6),
                _createElementVNode("span", _hoisted_7, _toDisplayString(handleMemberName(item)), 1)
              ], 10, _hoisted_5))
            }), 128))
          ])
        ], 512)
      ], 2)
    ]),
    _: 1
  }, 8, ["show"]))
}
}

})