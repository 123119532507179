import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, withModifiers as _withModifiers, withCtx as _withCtx, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  class: /*@__PURE__*/_normalizeClass(['toolbar-item-container-uni-title'])
}

import { ref } from '../../../../adapter-vue';
import { outsideClick } from '@tencentcloud/universal-api';
import Icon from '../../../common/Icon.vue';
import BottomPopup from '../../../common/BottomPopup/index.vue';
import { isPC, isUniFrameWork } from '../../../../utils/env';
import TUIChatConfig from '../../config';


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
  iconFile: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    default: '',
  },
  needDialog: {
    type: Boolean,
    default: true,
  },
  iconWidth: {
    type: String,
    default: '20px',
  },
  iconHeight: {
    type: String,
    default: '20px',
  },
  // Whether to display the bottom popup dialog on mobile devices
  // Invalid on PC
  needBottomPopup: {
    type: Boolean,
    default: false,
  },
},
  emits: ['onIconClick', 'onDialogClose', 'onDialogShow'],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props;

const emits = __emit;

const isDark = ref(TUIChatConfig.getTheme() === 'dark');
const showDialog = ref(false);
const toolbarItemRef = ref();
const dialogRef = ref();

const toggleToolbarItem = () => {
  emits('onIconClick', dialogRef);
  if (isPC) {
    outsideClick.listen({
      domRefs: toolbarItemRef.value,
      handler: closeToolbarItem,
    });
  }
  if (!props.needDialog) {
    return;
  }
  toggleDialogDisplay(!showDialog.value);
};

const closeToolbarItem = () => {
  showDialog.value = false;
  emits('onDialogClose', dialogRef);
};

const toggleDialogDisplay = (showStatus: boolean) => {
  if (showDialog.value === showStatus) {
    return;
  }
  showDialog.value = showStatus;
  switch (showStatus) {
    case true:
      emits('onDialogShow', dialogRef);
      break;
    case false:
      emits('onDialogClose', dialogRef);
  }
};

const onPopupClose = () => {
  showDialog.value = false;
};

__expose({
  toggleDialogDisplay,
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    ref_key: "toolbarItemRef",
    ref: toolbarItemRef,
    class: _normalizeClass([
      'toolbar-item-container',
      !_unref(isPC) && 'toolbar-item-container-h5',
      _unref(isUniFrameWork) && 'toolbar-item-container-uni',
    ])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass([
        'toolbar-item-container-icon',
        _unref(isUniFrameWork) && 'toolbar-item-container-uni-icon',
      ]),
      onClick: toggleToolbarItem
    }, [
      _createVNode(Icon, {
        file: props.iconFile,
        class: "icon",
        width: props.iconWidth,
        height: props.iconHeight
      }, null, 8, ["file", "width", "height"])
    ], 2),
    (_unref(isUniFrameWork))
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(props.title), 1))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("div", {
      ref_key: "dialogRef",
      ref: dialogRef,
      class: _normalizeClass([
        'toolbar-item-container-dialog',
        _unref(isDark) && 'toolbar-item-container-dialog-dark',
        !_unref(isPC) && 'toolbar-item-container-h5-dialog',
        _unref(isUniFrameWork) && 'toolbar-item-container-uni-dialog',
      ])
    }, [
      (props.needBottomPopup && !_unref(isPC))
        ? (_openBlock(), _createBlock(BottomPopup, {
            key: 0,
            class: "toolbar-bottom-popup",
            show: _unref(showDialog),
            onTouchmove: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop","prevent"])),
            onOnClose: onPopupClose
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "default")
            ]),
            _: 3
          }, 8, ["show"]))
        : _renderSlot(_ctx.$slots, "default", { key: 1 })
    ], 2), [
      [_vShow, _unref(showDialog)]
    ])
  ], 2))
}
}

})