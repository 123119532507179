import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "input-quote-content" }
const _hoisted_2 = { class: "max-one-line" }

import { ref, computed, onMounted, onUnmounted } from '../../../../adapter-vue';
import TUIChatEngine, {
  TUIStore,
  StoreName,
  TUITranslateService,
  IMessageModel,
} from '@tencentcloud/chat-uikit-engine';
import Icon from '../../../common/Icon.vue';
import closeIcon from '../../../../assets/icon/icon-close.svg';
import { isH5, isUniFrameWork } from '../../../../utils/env';
import { transformTextWithKeysToEmojiNames } from '../../emoji-config';
import { InputDisplayType } from '../../../../interface';

interface IProps {
  displayType?: InputDisplayType;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
    displayType: { default: 'editor' }
  },
  setup(__props: any) {

const props = __props;

const TYPES = TUIChatEngine.TYPES;
const quoteMessage = ref<IMessageModel>();

onMounted(() => {
  TUIStore.watch(StoreName.CHAT, {
    quoteMessage: onQuoteMessageUpdated,
  });
});

onUnmounted(() => {
  TUIStore.unwatch(StoreName.CHAT, {
    quoteMessage: onQuoteMessageUpdated,
  });
});

const quoteContentText = computed(() => {
  let _quoteContentText;
  switch (quoteMessage.value?.type) {
    case TYPES.MSG_TEXT:
      _quoteContentText = transformTextWithKeysToEmojiNames(quoteMessage.value.payload?.text);
      break;
    case TYPES.MSG_IMAGE:
      _quoteContentText = TUITranslateService.t('TUIChat.图片');
      break;
    case TYPES.MSG_AUDIO:
      _quoteContentText = TUITranslateService.t('TUIChat.语音');
      break;
    case TYPES.MSG_VIDEO:
      _quoteContentText = TUITranslateService.t('TUIChat.视频');
      break;
    case TYPES.MSG_FILE:
      _quoteContentText = TUITranslateService.t('TUIChat.文件');
      break;
    case TYPES.MSG_CUSTOM:
      _quoteContentText = TUITranslateService.t('TUIChat.自定义');
      break;
    case TYPES.MSG_FACE:
      _quoteContentText = TUITranslateService.t('TUIChat.表情');
      break;
    case TYPES.MSG_MERGER:
      _quoteContentText = TUITranslateService.t('TUIChat.聊天记录');
      break;
    default:
      _quoteContentText = TUITranslateService.t('TUIChat.消息');
      break;
  }
  return _quoteContentText;
});

function cancelQuote() {
  TUIStore.update(StoreName.CHAT, 'quoteMessage', { message: undefined, type: 'quote' });
}

function onQuoteMessageUpdated(options?: { message: IMessageModel; type: string }) {
  if (options?.message && options?.type === 'quote') {
    quoteMessage.value = options.message;
  } else {
    quoteMessage.value = undefined;
  }
}

return (_ctx: any,_cache: any) => {
  return (Boolean(_unref(quoteMessage)) && props.displayType !== 'audio')
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass({
      'input-quote-container': true,
      'input-quote-container-uni': _unref(isUniFrameWork),
      'input-quote-container-h5': _unref(isH5),
    })
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, _toDisplayString(_unref(quoteMessage).nick || _unref(quoteMessage).from) + ": " + _toDisplayString(_unref(quoteContentText)), 1),
          _createVNode(Icon, {
            class: "input-quote-close-icon",
            file: _unref(closeIcon),
            width: "11px",
            height: "11px",
            onOnClick: cancelQuote
          }, null, 8, ["file"])
        ])
      ], 2))
    : _createCommentVNode("", true)
}
}

})