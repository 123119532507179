import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, isRef as _isRef, vModelText as _vModelText, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["onClick"]

import TUIChatEngine, {
  TUITranslateService,
  TUIStore,
  StoreName,
  IConversationModel,
  TUIChatService,
  SendMessageParams,
  SendMessageOptions,
} from '@tencentcloud/chat-uikit-engine';
import { ref, computed } from '../../../../adapter-vue';
import ToolbarItemContainer from '../toolbar-item-container/index.vue';
import evaluateIconLight from '../../../../assets/icon/evalute-light.svg';
import evaluateIconDark from '../../../../assets/icon/evalute-dark.svg';
import Link from '../../../../utils/documentLink';
import Icon from '../../../common/Icon.vue';
import starIcon from '../../../../assets/icon/star.png';
import starLightIcon from '../../../../assets/icon/star-light.png';
import { CHAT_MSG_CUSTOM_TYPE } from '../../../../constant';
import { isPC, isH5, isUniFrameWork } from '../../../../utils/env';
import { isEnabledMessageReadReceiptGlobal } from '../../utils/utils';
import OfflinePushInfoManager, { IOfflinePushInfoCreateParams } from '../../offlinePushInfoManager/index';
import TUIChatConfig from '../../config';


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
  starTotal: {
    type: Number,
    default: 5,
  },
},
  emits: ['onDialogPopupShowOrHide'],
  setup(__props, { emit: __emit }) {

const evaluateIcon = TUIChatConfig.getTheme() === 'dark' ? evaluateIconDark : evaluateIconLight;
const props = __props;
const emits = __emit;

const container = ref();

const starList = ref<number>(props.starTotal);
const currentStarIndex = ref<number>(-1);
const comment = ref('');
const currentConversation = ref<IConversationModel>();

TUIStore.watch(StoreName.CONV, {
  currentConversation: (conversation: IConversationModel) => {
    currentConversation.value = conversation;
  },
});

const isEvaluateValid = computed(() => comment.value.length || currentStarIndex.value >= 0);

const onDialogShow = () => {
  emits('onDialogPopupShowOrHide', true);
};

const onDialogClose = () => {
  resetEvaluate();
  emits('onDialogPopupShowOrHide', false);
};

const openLink = () => {
  if (isPC || isH5) {
    window.open(Link?.customMessage?.url);
  }
};

const closeDialog = () => {
  container?.value?.toggleDialogDisplay(false);
};

const resetEvaluate = () => {
  currentStarIndex.value = -1;
  comment.value = '';
};

const selectStar = (starIndex?: any) => {
  if (currentStarIndex.value === starIndex) {
    currentStarIndex.value = currentStarIndex.value - 1;
  } else {
    currentStarIndex.value = starIndex;
  }
};

const submitEvaluate = () => {
  // The evaluate message must have at least one star or comment to be submitted.
  if (currentStarIndex.value < 0 && !comment.value.length) {
    return;
  }
  const payload = {
    data: JSON.stringify({
      businessID: CHAT_MSG_CUSTOM_TYPE.EVALUATE,
      version: 1,
      score: currentStarIndex.value + 1,
      comment: comment.value,
    }),
    description: '对本次的服务评价',
    extension: '对本次的服务评价',
  };
  const options = {
    to:
      currentConversation?.value?.groupProfile?.groupID
      || currentConversation?.value?.userProfile?.userID,
    conversationType: currentConversation?.value?.type,
    payload,
    needReadReceipt: isEnabledMessageReadReceiptGlobal(),
  };
  const offlinePushInfoCreateParams: IOfflinePushInfoCreateParams = {
    conversation: currentConversation.value,
    payload: options.payload,
    messageType: TUIChatEngine.TYPES.MSG_CUSTOM,
  };
  const sendMessageOptions: SendMessageOptions = {
    offlinePushInfo: OfflinePushInfoManager.create(offlinePushInfoCreateParams),
  };
  TUIChatService.sendCustomMessage(options as SendMessageParams, sendMessageOptions);
  // close dialog after submit evaluate
  container?.value?.toggleDialogDisplay(false);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(ToolbarItemContainer, {
    ref_key: "container",
    ref: container,
    iconFile: _unref(evaluateIcon),
    title: "评价",
    needBottomPopup: true,
    iconWidth: _unref(isUniFrameWork) ? '26px' : '20px',
    iconHeight: _unref(isUniFrameWork) ? '26px' : '20px',
    onOnDialogShow: onDialogShow,
    onOnDialogClose: onDialogClose
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(['evaluate', !_unref(isPC) && 'evaluate-h5'])
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(['evaluate-header', !_unref(isPC) && 'evaluate-h5-header'])
        }, [
          _createElementVNode("div", {
            class: _normalizeClass([
            'evaluate-header-content',
            !_unref(isPC) && 'evaluate-h5-header-content',
          ])
          }, _toDisplayString(_unref(TUITranslateService).t("Evaluate.请对本次服务进行评价")), 3),
          (!_unref(isPC))
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass([
            'evaluate-header-close',
            !_unref(isPC) && 'evaluate-h5-header-close',
          ]),
                onClick: _withModifiers(closeDialog, ["stop"])
              }, _toDisplayString(_unref(TUITranslateService).t("关闭")), 3))
            : _createCommentVNode("", true)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(['evaluate-content', !_unref(isPC) && 'evaluate-h5-content'])
        }, [
          _createElementVNode("ul", {
            class: _normalizeClass([
            'evaluate-content-list',
            !_unref(isPC) && 'evaluate-h5-content-list',
          ])
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(starList), (item, index) => {
              return (_openBlock(), _createElementBlock("li", {
                key: index,
                class: _normalizeClass([
              'evaluate-content-list-item',
              !_unref(isPC) && 'evaluate-h5-content-list-item',
            ]),
                onClick: _withModifiers(($event: any) => (selectStar(index)), ["stop"])
              }, [
                (index <= _unref(currentStarIndex))
                  ? (_openBlock(), _createBlock(Icon, {
                      key: 0,
                      file: _unref(starLightIcon),
                      width: _unref(isPC) ? '20px' : '30px',
                      height: _unref(isPC) ? '20px' : '30px'
                    }, null, 8, ["file", "width", "height"]))
                  : (_openBlock(), _createBlock(Icon, {
                      key: 1,
                      file: _unref(starIcon),
                      width: _unref(isPC) ? '20px' : '30px',
                      height: _unref(isPC) ? '20px' : '30px'
                    }, null, 8, ["file", "width", "height"]))
              ], 10, _hoisted_1))
            }), 128))
          ], 2),
          _withDirectives(_createElementVNode("textarea", {
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(comment) ? (comment).value = $event : null)),
            class: _normalizeClass([
            'evaluate-content-text',
            !_unref(isPC) && 'evaluate-h5-content-text',
          ])
          }, null, 2), [
            [_vModelText, _unref(comment)]
          ]),
          _createElementVNode("div", {
            class: _normalizeClass([
            'evaluate-content-button',
            !_unref(isPC) && 'evaluate-h5-content-button',
          ])
          }, [
            _createElementVNode("button", {
              class: _normalizeClass(['btn', _unref(isEvaluateValid) ? 'btn-valid' : 'btn-invalid']),
              onClick: submitEvaluate
            }, _toDisplayString(_unref(TUITranslateService).t("Evaluate.提交评价")), 3)
          ], 2)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(['evaluate-adv', !_unref(isPC) && 'evaluate-h5-adv'])
        }, [
          _createTextVNode(_toDisplayString(_unref(TUITranslateService).t("Evaluate.服务评价工具")) + " " + _toDisplayString("(" + _unref(TUITranslateService).t("Evaluate.使用")) + " ", 1),
          _createElementVNode("a", {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (openLink(_unref(Link).customMessage)))
          }, _toDisplayString(_unref(TUITranslateService).t(`Evaluate.${_unref(Link).customMessage.label}`)), 1),
          _createTextVNode(" " + _toDisplayString(_unref(TUITranslateService).t("Evaluate.搭建") + ")"), 1)
        ], 2)
      ], 2)
    ]),
    _: 1
  }, 8, ["iconFile", "iconWidth", "iconHeight"]))
}
}

})