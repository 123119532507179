import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "message-image" }
const _hoisted_2 = ["src"]

import { ref, onMounted } from '../../../../adapter-vue';
import { CUSTOM_BIG_EMOJI_URL } from '../../emoji-config';


export default /*@__PURE__*/_defineComponent({
  __name: 'message-face',
  props: {
  content: {
    type: Object,
    default: () => ({}),
  },
},
  setup(__props) {

const props = __props;

const url = ref(props.content.url);

onMounted(() => {
  if (props.content.type === 'custom') {
    if (!CUSTOM_BIG_EMOJI_URL) {
      console.warn('CUSTOM_BIG_EMOJI_URL is required for custom emoji, please check your CUSTOM_BIG_EMOJI_URL.');
    } else {
      url.value = CUSTOM_BIG_EMOJI_URL + props.content.name;
    }
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      mode: "aspectFit",
      class: "message-image",
      src: _unref(url)
    }, null, 8, _hoisted_2)
  ]))
}
}

})