import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "message-input-button-hover"
}

import { ref } from '../../../adapter-vue';
import { TUITranslateService } from '@tencentcloud/chat-uikit-engine';
import { TUIConstants } from '@tencentcloud/tui-core';
import { isPC } from '../../../utils/env';
import TUIChatConfig from '../config';


export default /*@__PURE__*/_defineComponent({
  __name: 'message-input-button',
  props: {
  enableSend: {
    type: Boolean,
    default: true,
  },
},
  emits: ['sendMessage'],
  setup(__props, { emit: __emit }) {

const props = __props;

const displayHover = ref(TUIChatConfig.getChatType() !== TUIConstants.TUIChat.TYPE.ROOM);

const emits = __emit;

const sendMessage = () => {
  emits('sendMessage');
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['message-input-button', !_unref(isPC) && 'message-input-button-h5'])
  }, [
    (props.enableSend)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          class: "message-input-button-cont",
          "data-type": "text",
          disabled: false,
          onClick: sendMessage
        }, [
          (_unref(displayHover))
            ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(_unref(TUITranslateService).t("TUIChat.按Enter发送，Ctrl+Enter换行")), 1))
            : _createCommentVNode("", true),
          _createTextVNode(" " + _toDisplayString(_unref(TUITranslateService).t("发送")), 1)
        ]))
      : _createCommentVNode("", true)
  ], 2))
}
}

})