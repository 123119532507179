import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { IMessageModel } from '@tencentcloud/chat-uikit-engine';
import TUICustomerServicePlugin from '@tencentcloud/tui-customer-service-plugin/index.vue';
interface IProps {
  message: IMessageModel;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'message-customer-service',
  props: {
    message: { default: () => ({} as IMessageModel) }
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(TUICustomerServicePlugin, {
    message: props.message
  }, null, 8, ["message"]))
}
}

})