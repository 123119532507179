import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

import {
  computed,
  CSSProperties,
  onMounted,
  ref,
  watch,
} from '../../../adapter-vue';
import { isH5 } from '../../../utils/env';
import TOAST_TYPE from './type';


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
  message: {
    type: String,
    default: '',
  },
  duration: {
    type: Number,
    default: 3000,
  },
  repeatNum: {
    type: Number,
    default: 1,
  },
  id: {
    type: String,
    default: '',
  },
  onClose: {
    type: Function,
    required: false,
  },
  offset: {
    type: Number,
    default: 20,
  },
  zIndex: {
    type: Number,
    default: 0,
  },
  type: {
    type: String,
    default: '',
  },
},
  setup(__props) {

const props = __props;
const visible = ref(false);
let timer: any;

const startTimer = () => {
  if (props.duration > 0) {
    timer = setTimeout(() => {
      if (visible.value) {
        close();
      }
    }, props.duration);
  }
};

const clearTimer = () => {
  clearTimeout(timer);
};

const close = () => {
  visible.value = false;
  if (typeof props.onClose === 'function') {
    props.onClose();
  }
};

watch(
  () => props.repeatNum,
  () => {
    clearTimer();
    startTimer();
  },
);

const customStyle = computed<CSSProperties>(() => ({
  top: `${props.offset}px`,
  zIndex: props.zIndex,
}));

onMounted(() => {
  startTimer();
  visible.value = true;
});

const handleStyle = (type?: string) => {
  if (
    type
      && (type === TOAST_TYPE.ERROR
      || type === TOAST_TYPE.SUCCESS
      || type === TOAST_TYPE.WARNING)
  )
    return type;
  return TOAST_TYPE.NORMAL;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_Transition, {
    name: "fade",
    onAfterLeave: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('destroy')))
  }, {
    default: _withCtx(() => [
      _withDirectives(_createElementVNode("div", {
        class: _normalizeClass(["message", [handleStyle(__props.type), _unref(isH5) && 'message-h5']]),
        style: _normalizeStyle(_unref(customStyle))
      }, [
        (!_unref(isH5))
          ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(__props.message), 1))
          : _createCommentVNode("", true),
        (_unref(isH5))
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(__props.message), 1))
          : _createCommentVNode("", true)
      ], 6), [
        [_vShow, _unref(visible)]
      ])
    ]),
    _: 1
  }))
}
}

})