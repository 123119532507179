import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "emoji-picker-tab" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["src"]
const _hoisted_8 = ["src"]

import { ref, onMounted, onUnmounted } from '../../../../adapter-vue';
import {
  TUIChatService,
  TUIStore,
  StoreName,
  IConversationModel,
  SendMessageParams,
} from '@tencentcloud/chat-uikit-engine';
import Icon from '../../../common/Icon.vue';
import faceIconLight from '../../../../assets/icon/face-light.svg';
import faceIconDark from '../../../../assets/icon/face-dark.svg';
import { EMOJI_TYPE } from '.././../../../constant';
import { isPC, isUniFrameWork } from '../../../../utils/env';
import { IEmojiGroupList, IEmojiGroup } from '../../../../interface';
import { isEnabledMessageReadReceiptGlobal } from '../../utils/utils';
import { EMOJI_GROUP_LIST, BASIC_EMOJI_URL_MAPPING, convertKeyToEmojiName } from '../../emoji-config';
import TUIChatConfig from '../../config';


export default /*@__PURE__*/_defineComponent({
  __name: 'emoji-picker-dialog',
  emits: ['insertEmoji', 'onClose', 'sendMessage'],
  setup(__props, { emit: __emit }) {

const faceIcon = TUIChatConfig.getTheme() === 'dark' ? faceIconDark : faceIconLight;
const emits = __emit;
const currentTabIndex = ref<number>(0);
const currentConversation = ref();
const emojiPickerDialog = ref();
const emojiPickerListRef = ref();
const featureConfig = TUIChatConfig.getFeatureConfig();
const list = ref<IEmojiGroupList>(initEmojiList());
const currentTabItem = ref<IEmojiGroup>(list?.value[0]);
const currentEmojiList = ref<string[]>(list?.value[0]?.list);

onMounted(() => {
  TUIStore.watch(StoreName.CONV, {
    currentConversation: onCurrentConversationUpdate,
  });
});

onUnmounted(() => {
  TUIStore.unwatch(StoreName.CONV, {
    currentConversation: onCurrentConversationUpdate,
  });
});

const toggleEmojiTab = (index: number) => {
  currentTabIndex.value = index;
  currentTabItem.value = list?.value[index];
  currentEmojiList.value = list?.value[index]?.list;
  // web & h5 side scroll to top
  if (!isUniFrameWork) {
    emojiPickerListRef?.value && (emojiPickerListRef.value.scrollTop = 0);
  }
};

const select = (item: any, index: number) => {
  const options: any = {
    emoji: { key: item, name: convertKeyToEmojiName(item) },
    type: currentTabItem?.value?.type,
  };
  switch (currentTabItem?.value?.type) {
    case EMOJI_TYPE.BASIC:
      options.url = currentTabItem?.value?.url + BASIC_EMOJI_URL_MAPPING[item];
      if (isUniFrameWork) {
        uni.$emit('insert-emoji', options);
      } else {
        emits('insertEmoji', options);
      }
      break;
    case EMOJI_TYPE.BIG:
      sendFaceMessage(index, currentTabItem.value);
      break;
    case EMOJI_TYPE.CUSTOM:
      sendFaceMessage(index, currentTabItem.value);
      break;
    default:
      break;
  }
  isPC && emits('onClose');
};

const sendFaceMessage = (index: number, listItem: IEmojiGroup) => {
  const options = {
    to:
      currentConversation?.value?.groupProfile?.groupID
      || currentConversation?.value?.userProfile?.userID,
    conversationType: currentConversation?.value?.type,
    payload: {
      index: listItem.emojiGroupID,
      data: listItem.list[index],
    },
    needReadReceipt: isEnabledMessageReadReceiptGlobal(),
  } as SendMessageParams;
  TUIChatService.sendFaceMessage(options);
};

function sendMessage() {
  uni.$emit('send-message-in-emoji-picker');
}

function onCurrentConversationUpdate(conversation: IConversationModel) {
  currentConversation.value = conversation;
}

function initEmojiList() {
  return EMOJI_GROUP_LIST.filter((item) => {
    if (item.type === EMOJI_TYPE.BASIC) {
      return featureConfig.InputEmoji;
    }
    if (item.type === EMOJI_TYPE.BIG) {
      return featureConfig.InputStickers;
    }
  });
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    ref_key: "emojiPickerDialog",
    ref: emojiPickerDialog,
    class: _normalizeClass({
      'emoji-picker': true,
      'emoji-picker-h5': !_unref(isPC)
    })
  }, [
    _createElementVNode("ul", {
      ref_key: "emojiPickerListRef",
      ref: emojiPickerListRef,
      class: _normalizeClass(['emoji-picker-list', !_unref(isPC) && 'emoji-picker-h5-list'])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(currentEmojiList), (childrenItem, childrenIndex) => {
        return (_openBlock(), _createElementBlock("li", {
          key: childrenIndex,
          class: "emoji-picker-list-item",
          onClick: ($event: any) => (select(childrenItem, childrenIndex))
        }, [
          (_unref(currentTabItem).type === _unref(EMOJI_TYPE).BASIC)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                class: "emoji",
                src: _unref(currentTabItem).url + _unref(BASIC_EMOJI_URL_MAPPING)[childrenItem]
              }, null, 8, _hoisted_2))
            : (_unref(currentTabItem).type === _unref(EMOJI_TYPE).BIG)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 1,
                  class: "emoji-big",
                  src: _unref(currentTabItem).url + childrenItem + '@2x.png'
                }, null, 8, _hoisted_3))
              : (_openBlock(), _createElementBlock("img", {
                  key: 2,
                  class: "emoji-custom emoji-big",
                  src: _unref(currentTabItem).url + childrenItem
                }, null, 8, _hoisted_4))
        ], 8, _hoisted_1))
      }), 128))
    ], 2),
    _createElementVNode("ul", _hoisted_5, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(list), (item, index) => {
        return (_openBlock(), _createElementBlock("li", {
          key: index,
          class: "emoji-picker-tab-item",
          onClick: ($event: any) => (toggleEmojiTab(index))
        }, [
          (item.type === _unref(EMOJI_TYPE).BASIC)
            ? (_openBlock(), _createBlock(Icon, {
                key: 0,
                class: "icon",
                file: _unref(faceIcon)
              }, null, 8, ["file"]))
            : (item.type === _unref(EMOJI_TYPE).BIG)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 1,
                  class: "icon-big",
                  src: item.url + item.list[0] + '@2x.png'
                }, null, 8, _hoisted_7))
              : (_openBlock(), _createElementBlock("img", {
                  key: 2,
                  class: "icon-custom icon-big",
                  src: item.url + item.list[0]
                }, null, 8, _hoisted_8))
        ], 8, _hoisted_6))
      }), 128)),
      (_unref(isUniFrameWork))
        ? (_openBlock(), _createElementBlock("li", {
            key: 0,
            class: "send-btn",
            onClick: sendMessage
          }, " 发送 "))
        : _createCommentVNode("", true)
    ])
  ], 2))
}
}

})