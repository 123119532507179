import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

import { computed } from '../../../adapter-vue';
import TUIChatEngine, { IMessageModel } from '@tencentcloud/chat-uikit-engine';

interface IProps {
  message: IMessageModel;
  signalingInfo: any;
  customContent: any;
  blinkMessageIDList?: string[];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'message-call-group',
  props: {
    message: { default: () => ({}) as IMessageModel },
    signalingInfo: { default: () => ({}) },
    customContent: { default: () => ({}) },
    blinkMessageIDList: { default: () => [] }
  },
  setup(__props: any) {

const props = __props;

const TYPES = TUIChatEngine.TYPES;
const isCallMessage = computed(() => !!props.signalingInfo);
const conversationType = computed(() => props.message?.conversationType);
const custom = computed(() => props.customContent?.custom);

const isBlink = computed(() => {
  if (props.message?.ID) {
    return props.blinkMessageIDList?.includes(props.message.ID);
  }
  return false;
});

return (_ctx: any,_cache: any) => {
  return (_unref(isCallMessage) && _unref(conversationType) === _unref(TYPES).CONV_GROUP)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass({ 'blink-text': _unref(isBlink) })
      }, _toDisplayString(_unref(custom)), 3))
    : _createCommentVNode("", true)
}
}

})