<template>
  <div id="app" >
  <TUIChat />
  <TUICallKit  class="callkit-container" :allowedMinimized="true" :allowedFullScreen="false" />
  </div>
</template>
<script lang="ts" setup>
import { TUIKit,TUIChat} from './TUIKit';
import { TUICallKit } from '@tencentcloud/call-uikit-vue';
import { TUIGroupService, TUIConversationService,TUIUserService} from '@tencentcloud/chat-uikit-engine';
import { TUILogin } from '@tencentcloud/tui-core';
import { framework } from './TUIKit/adapter-vue';
import TencentCloudChat from '@tencentcloud/chat';
import LibGenerateTestUserSig from './lib-generate-test-usersig-es.min.js';
import { ref } from 'vue';

const searchParams = new URLSearchParams(window.location.search);
const url = window.location.href;
const userID = searchParams.get('user_id');
const groupID = searchParams.get('group_id');
const nickname = searchParams.get('nickname');
const user_img = searchParams.get('user_img');
const EXPIRETIME = 604800;
const SDKAppID = 1400666197;
const secretKey = "1dbcd9fe044ce9e3a79c59ec15598db8927ae2c8d46f651202b6bd5f6a82575a";
const generator = new LibGenerateTestUserSig(SDKAppID, secretKey, EXPIRETIME);
const userSig = generator.genTestUserSig(userID);
const login = () => {
  TUILogin.login({
  SDKAppID: SDKAppID,
  userID: userID,
  userSig: userSig,
  useUploadPlugin: true,
  framework,
}).then(() => {
TUIGroupService.joinGroup({groupID:groupID});
update();
siwtch();
})
.catch((error) => {console.log(error);})
};

const update = () => {
  TUIUserService.updateMyProfile({
   nick: nickname,
   avatar: user_img,
   allowType: TencentCloudChat.TYPES.ALLOW_TYPE_DENY_ANY,
   gender: TencentCloudChat.TYPES.GENDER_UNKNOWN
  }).catch((error) => {console.log(error);})
};

const siwtch = () => {
  try {
    TUIConversationService.switchConversation("GROUP"+groupID);
  } catch (error) {
  console.log('An error occurred: ' + error.message);}
};

window.addEventListener('unload', (event) => {

  TUILogin.logout();
});
login();
</script>
<style lang="scss">
body, html {
  height: 100%;
  margin: 0;
  padding: 0;
}
#app {
  height: 100%
}
</style>