const components = {
  "取消": "取消",
  "完成": "完成",
  "已选中": "已選中",
  "人": "人",
  "保存": "保存",
  "请输入userID": "請輸入userID",
  "请输入groupID": "請輸入groupID",
  "确定": "確定",
  "全选": "全選",
  "已在群聊中": "已在群聊中",
  "查看更多": "查看更多",
  "图片 url 不存在": "圖片 url 不存在",
  "已取消": "已取消",
  "获取权限失败": "獲取權限失敗",
  "已保存至相册": "已保存至相冊",
  "图片下载失败": "圖片下載失敗",
  "暂不支持下载此类型图片": "暫不支持下載此類型圖片",
};

export default components;
