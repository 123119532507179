import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withKeys as _withKeys, normalizeClass as _normalizeClass, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "transfer-header transfer-h5-header"
}
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "main" }
const _hoisted_4 = { class: "left" }
const _hoisted_5 = { class: "transfer-header" }
const _hoisted_6 = ["value", "placeholder"]
const _hoisted_7 = ["placeholder", "value"]
const _hoisted_8 = { class: "transfer-left-main" }
const _hoisted_9 = { class: "transfer-list" }
const _hoisted_10 = {
  key: 1,
  class: "icon-unselected"
}
const _hoisted_11 = { class: "select-all" }
const _hoisted_12 = ["onClick"]
const _hoisted_13 = ["src"]
const _hoisted_14 = { class: "name" }
const _hoisted_15 = { key: 0 }
const _hoisted_16 = { class: "right" }
const _hoisted_17 = {
  key: 0,
  class: "transfer-header"
}
const _hoisted_18 = {
  key: 1,
  class: "transfer-list"
}
const _hoisted_19 = {
  key: 0,
  class: "transfer-text"
}
const _hoisted_20 = { class: "transfer-list-item-content" }
const _hoisted_21 = ["src"]
const _hoisted_22 = {
  key: 0,
  class: "name"
}
const _hoisted_23 = ["onClick"]
const _hoisted_24 = { class: "transfer-right-footer" }

import { ref, watchEffect, computed } from '../../../adapter-vue';
import { TUITranslateService } from '@tencentcloud/chat-uikit-engine';
import { ITransferListItem } from '../../../interface';
import Icon from '../Icon.vue';
import selectedIcon from '../../../assets/icon/selected.svg';
import backIcon from '../../../assets/icon/back.svg';
import cancelIcon from '../../../assets/icon/cancel.svg';
import { isPC, isUniFrameWork, isMobile } from '../../../utils/env';


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
  list: {
    type: Array,
    default: () => [],
  },
  selectedList: {
    type: Array,
    default: () => [],
  },
  isSearch: {
    type: Boolean,
    default: true,
  },
  isRadio: {
    type: Boolean,
    default: false,
  },
  isCustomItem: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: '',
  },
  type: {
    type: String,
    default: '',
  },
  resultShow: {
    type: Boolean,
    default: true,
  },
  total: {
    type: Number,
    default: 0,
  },
  isHiddenBackIcon: {
    type: Boolean,
    default: false,
  },
},
  emits: ['search', 'submit', 'cancel', 'getMore'],
  setup(__props, { emit: __emit }) {

const props = __props;

const transferList = ref<ITransferListItem[]>([]);
const transferTotal = ref<number>(0);
const transferSelectedList = ref<ITransferListItem[]>([]);
const isTransferSearch = ref(true);
const isTransferCustomItem = ref(false);
const transferTitle = ref('');
const searchValue = ref('');

watchEffect(() => {
  if (props.isCustomItem) {
    for (let index = 0; index < props.list.length; index++) {
      if (
        (props.list[index] as any).conversationID.indexOf('@TIM#SYSTEM') > -1
      ) {
        // eslint-disable-next-line vue/no-mutating-props
        props.list.splice(index, 1);
      }
      transferList.value = props.list as ITransferListItem[];
    }
  } else {
    transferList.value = props.list as ITransferListItem[];
  }
  transferTotal.value = props.total ? props.total : props.list.length;
  transferSelectedList.value = (props.selectedList && props.selectedList.length > 0 ? props.selectedList : transferSelectedList.value) as any;
  isTransferSearch.value = props.isSearch;
  isTransferCustomItem.value = props.isCustomItem;
  transferTitle.value = props.title;
});

const emit = __emit;

const optional = computed(() =>
  transferList.value.filter((item: any) => !item.isDisabled),
);

const handleInput = (e: any) => {
  searchValue.value = e.target.value || e.detail.value;
  emit('search', searchValue.value);
};
const selected = (item: any) => {
  if (item.isDisabled) {
    return;
  }
  let list: ITransferListItem[] = transferSelectedList.value;
  const index: number = list.indexOf(item);
  if (index > -1) {
    return transferSelectedList.value.splice(index, 1);
  }
  if (props.isRadio) {
    list = [];
  }
  list.push(item);
  transferSelectedList.value = list;
};

const selectedAll = () => {
  if (transferSelectedList.value.length === optional.value.length) {
    transferSelectedList.value = [];
  } else {
    transferSelectedList.value = [...optional.value];
  }
};

const submit = () => {
  emit('submit', transferSelectedList.value);
  searchValue.value = '';
};

const cancel = () => {
  emit('cancel');
  searchValue.value = '';
};

const getMore = () => {
  emit('getMore');
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["transfer", [!_unref(isPC) ? 'transfer-h5' : '', _unref(isMobile) ? 'transfer-h5-wechat' : '']])
  }, [
    (!_unref(isPC))
      ? (_openBlock(), _createElementBlock("header", _hoisted_1, [
          (!props.isHiddenBackIcon)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                onClick: cancel
              }, [
                _createVNode(Icon, {
                  class: "icon",
                  file: _unref(backIcon),
                  width: '18px',
                  height: '18px'
                }, null, 8, ["file"])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("span", _hoisted_2, _toDisplayString(_unref(transferTitle)), 1),
          _cache[0] || (_cache[0] = _createElementVNode("span", { class: "space" }, null, -1))
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("main", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("header", _hoisted_5, [
          (_unref(isPC) && _unref(isTransferSearch))
            ? (_openBlock(), _createElementBlock("input", {
                key: 0,
                type: "text",
                value: _unref(searchValue),
                placeholder: _unref(TUITranslateService).t('component.请输入userID'),
                enterkeyhint: "search",
                class: _normalizeClass([_unref(isUniFrameWork) ? 'left-uniapp-input' : '']),
                onKeyup: _withKeys(handleInput, ["enter"])
              }, null, 42, _hoisted_6))
            : _createCommentVNode("", true),
          (!_unref(isPC) && _unref(isTransferSearch))
            ? (_openBlock(), _createElementBlock("input", {
                key: 1,
                type: "text",
                placeholder: _unref(TUITranslateService).t('component.请输入userID'),
                enterkeyhint: "search",
                value: _unref(searchValue),
                class: _normalizeClass([_unref(isUniFrameWork) ? 'left-uniapp-input' : '']),
                onBlur: handleInput,
                onConfirm: handleInput
              }, null, 42, _hoisted_7))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("main", _hoisted_8, [
          _createElementVNode("ul", _hoisted_9, [
            (_unref(optional).length > 1 && !__props.isRadio)
              ? (_openBlock(), _createElementBlock("li", {
                  key: 0,
                  class: "transfer-list-item",
                  onClick: selectedAll
                }, [
                  (_unref(transferSelectedList).length === _unref(optional).length)
                    ? (_openBlock(), _createBlock(Icon, {
                        key: 0,
                        file: _unref(selectedIcon),
                        width: '18px',
                        height: '18px'
                      }, null, 8, ["file"]))
                    : (_openBlock(), _createElementBlock("i", _hoisted_10)),
                  _createElementVNode("span", _hoisted_11, _toDisplayString(_unref(TUITranslateService).t("component.全选")), 1)
                ]))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(transferList), (item) => {
              return (_openBlock(), _createElementBlock("li", {
                key: item.userID,
                class: "transfer-list-item",
                onClick: ($event: any) => (selected(item))
              }, [
                (_unref(transferSelectedList).indexOf(item) > -1)
                  ? (_openBlock(), _createBlock(Icon, {
                      key: 0,
                      file: _unref(selectedIcon),
                      class: _normalizeClass([item.isDisabled && 'disabled']),
                      width: '18px',
                      height: '18px'
                    }, null, 8, ["file", "class"]))
                  : (_openBlock(), _createElementBlock("i", {
                      key: 1,
                      class: _normalizeClass([item.isDisabled && 'disabled', 'icon-unselected'])
                    }, null, 2)),
                (!_unref(isTransferCustomItem))
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                      _createElementVNode("img", {
                        class: "avatar",
                        src: 
                    item.avatar ||
                      'https://web.sdk.qcloud.com/component/TUIKit/assets/avatar_21.png'
                  ,
                        onerror: "this.onerror=null;this.src='https://web.sdk.qcloud.com/component/TUIKit/assets/avatar_21.png'"
                      }, null, 8, _hoisted_13),
                      _createElementVNode("span", _hoisted_14, _toDisplayString(item.nick || item.userID), 1),
                      (item.isDisabled)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_15, "（" + _toDisplayString(_unref(TUITranslateService).t("component.已在群中")) + "）", 1))
                        : _createCommentVNode("", true)
                    ], 64))
                  : _renderSlot(_ctx.$slots, "left", {
                      key: 3,
                      data: item
                    })
              ], 8, _hoisted_12))
            }), 128)),
            (_unref(transferTotal) > _unref(transferList).length)
              ? (_openBlock(), _createElementBlock("li", {
                  key: 1,
                  class: "transfer-list-item more",
                  onClick: getMore
                }, _toDisplayString(_unref(TUITranslateService).t("component.查看更多")), 1))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_16, [
        (_unref(isPC))
          ? (_openBlock(), _createElementBlock("header", _hoisted_17, _toDisplayString(_unref(transferTitle)), 1))
          : _createCommentVNode("", true),
        (__props.resultShow)
          ? (_openBlock(), _createElementBlock("ul", _hoisted_18, [
              (_unref(transferSelectedList).length > 0 && _unref(isPC))
                ? (_openBlock(), _createElementBlock("p", _hoisted_19, _toDisplayString(_unref(TUITranslateService).t("component.已选中")) + _toDisplayString(_unref(transferSelectedList).length) + _toDisplayString(_unref(TUITranslateService).t("component.人")), 1))
                : _createCommentVNode("", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(transferSelectedList), (item, index) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: index,
                  class: "transfer-list-item space-between"
                }, [
                  _createElementVNode("aside", _hoisted_20, [
                    (!_unref(isTransferCustomItem))
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _createElementVNode("img", {
                            class: "avatar",
                            src: 
                    item.avatar ||
                      'https://web.sdk.qcloud.com/component/TUIKit/assets/avatar_21.png'
                  ,
                            onerror: "this.onerror=null;this.src='https://web.sdk.qcloud.com/component/TUIKit/assets/avatar_21.png'"
                          }, null, 8, _hoisted_21),
                          (_unref(isPC))
                            ? (_openBlock(), _createElementBlock("span", _hoisted_22, _toDisplayString(item.nick || item.userID), 1))
                            : _createCommentVNode("", true)
                        ], 64))
                      : _renderSlot(_ctx.$slots, "right", {
                          key: 1,
                          data: item
                        })
                  ]),
                  (_unref(isPC))
                    ? (_openBlock(), _createElementBlock("span", {
                        key: 0,
                        onClick: ($event: any) => (selected(item))
                      }, [
                        _createVNode(Icon, {
                          file: _unref(cancelIcon),
                          width: '18px',
                          height: '18px'
                        }, null, 8, ["file"])
                      ], 8, _hoisted_23))
                    : _createCommentVNode("", true)
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("footer", _hoisted_24, [
          _createElementVNode("button", {
            class: "btn btn-cancel",
            onClick: cancel
          }, _toDisplayString(_unref(TUITranslateService).t("component.取消")), 1),
          (_unref(transferSelectedList).length > 0)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                class: "btn",
                onClick: submit
              }, _toDisplayString(_unref(TUITranslateService).t("component.完成")), 1))
            : (_openBlock(), _createElementBlock("button", {
                key: 1,
                class: "btn btn-no",
                onClick: submit
              }, _toDisplayString(_unref(TUITranslateService).t("component.完成")), 1))
        ])
      ])
    ])
  ], 2))
}
}

})