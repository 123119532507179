const Evaluate = {
  "请对本次服务进行评价": "請對本次服務進行評價",
  "提交评价": "提交評價",
  "服务评价工具": "服務評價工具",
  "使用": "使用",
  "自定义消息": "自定義消息",
  "搭建": "搭建",
  "星": "星"
}

export default Evaluate;
