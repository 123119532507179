import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle } from "vue"

import { ref, watch, nextTick } from '../../../../../adapter-vue';
import {
  IMessageModel,
  TUITranslateService,
} from '@tencentcloud/chat-uikit-engine';
import { getBoundingClientRectSync } from '@tencentcloud/universal-api';
import { convertor } from '../../../utils/convertVoiceToText';

interface IProps {
  message: IMessageModel;
  contentVisible: boolean;
  convertWrapperRef: HTMLDivElement | undefined;
  isSingleConvert?: boolean;
}

interface IEmits {
  (e: 'toggleErrorStatus', status: boolean): void;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'convert-content',
  props: {
    message: { default: () => ({} as IMessageModel) },
    contentVisible: { type: Boolean },
    convertWrapperRef: {},
    isSingleConvert: { type: Boolean, default: false }
  },
  emits: ["toggleErrorStatus"],
  setup(__props: any, { emit: __emit }) {

const emits = __emit;
const props = __props;

const convertFinished = ref<boolean>(false);
const convertText = ref<string>('');
const calculateHeight = ref<number>(0);
const calculateWidth = ref<number>(0);

const convertLoadingRef = ref<HTMLDivElement>();
const convertContentRef = ref<HTMLDivElement>();

watch(() => props.contentVisible, (newVal: boolean) => {
  if (newVal) {
    convertor.get(props.message)
      .then((text) => {
        convertFinished.value = true;
        convertText.value = text;
        nextTick(() => {
          const { height: originHeight, width: originWidth } = getBoundingClientRectSync(convertLoadingRef.value!);
          const { height, width } = getBoundingClientRectSync(convertContentRef.value!);
          calculateHeight.value = originHeight;
          calculateWidth.value = originWidth;
          requestAnimationFrame(() => {
            calculateHeight.value = height;
            calculateWidth.value = width;
            if (props.isSingleConvert) {
              nextTick(() => {
                const { bottom } = getBoundingClientRectSync(props.convertWrapperRef);
                const { bottom: bottomWindow } = getBoundingClientRectSync('#messageScrollList');
                if (bottom > bottomWindow) {
                  const timer = setTimeout(() => {
                    props.convertWrapperRef!.scrollIntoView({ block: 'end', behavior: 'smooth' });
                    clearTimeout(timer);
                  }, 150);
                }
              });
            }
          });
        });
      })
      .catch((err) => {
        convertFinished.value = true;
        emits('toggleErrorStatus', true);
        const { height: originHeight } = getBoundingClientRectSync(convertLoadingRef.value!);
        calculateHeight.value = originHeight;
        convertText.value = err.message;
      });
  }
}, {
  immediate: true,
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "message-convert-container",
    style: _normalizeStyle({
      height: _unref(calculateHeight) > 0 ? `${_unref(calculateHeight)}px` : 'auto',
      width: _unref(calculateWidth) > 0 ? `${_unref(calculateWidth)}px` : 'auto',
    })
  }, [
    (_unref(convertFinished))
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          ref_key: "convertContentRef",
          ref: convertContentRef,
          class: _normalizeClass({
        'convert-content': true,
        'occur': _unref(calculateHeight) > 0,
      })
        }, _toDisplayString(_unref(convertText)), 3))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      ref_key: "convertLoadingRef",
      ref: convertLoadingRef,
      class: _normalizeClass({
        'loading': true,
        'loading-end': _unref(convertFinished)
      })
    }, _toDisplayString(_unref(TUITranslateService).t('TUIChat.转换中')) + "... ", 3)
  ], 4))
}
}

})