import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
  value: {
    type: Boolean,
    default: false,
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['tui-switch', __props.value ? 'tui-switch-checked' : 'tui-switch-no-checked'])
  }, null, 2))
}
}

})