const TUIConversation = {
  "发起会话": "發起會話",
  "发起单聊": "發起單聊",
  "发起群聊": "發起群聊",
  "请输入userID": "請輸入userID",
  "删除会话": "刪除會話",
  "删除后，将清空该聊天的消息记录": "刪除後，將清空該聊天的消息記錄",
  "删除后，将清空该群聊的消息记录": "刪除後，將清空該群聊的消息記錄",
  "置顶会话": "置頂會話",
  "取消置顶": "取消置頂",
  "消息免打扰": "消息免打擾",
  "取消免打扰": "取消免打擾",
  "系统通知": "系統通知",
  "有人@我": "有人@我",
  "@所有人": "@所有人",
  "条": "條",
  "我": "我",
  "网络异常，请您检查网络设置": "網絡異常，請您檢查網絡設置",
  "您已被群管理员移出群聊": "您已被群管理員移出群聊",
  "该群聊已被解散": "該群聊已被解散",
  "您已退出该群聊": "您已退出該群聊",
}

export default TUIConversation;
