import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "custom" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["href"]
const _hoisted_4 = { key: 0 }
const _hoisted_5 = ["href"]
const _hoisted_6 = { key: 1 }
const _hoisted_7 = {
  key: 1,
  class: "evaluate"
}
const _hoisted_8 = { class: "evaluate-list" }
const _hoisted_9 = ["src"]
const _hoisted_10 = {
  key: 3,
  class: "textLink"
}
const _hoisted_11 = ["href"]
const _hoisted_12 = ["innerHTML"]

import { watchEffect, ref } from '../../../../adapter-vue';
import { TUITranslateService, IMessageModel } from '@tencentcloud/chat-uikit-engine';
import { isUrl, JSONToObject } from '../../../../utils/index';
import { CHAT_MSG_CUSTOM_TYPE } from '../../../../constant';
import { ICustomMessagePayload } from '../../../../interface';
import Icon from '../../../common/Icon.vue';
import star from '../../../../assets/icon/star-light.png';
interface Props {
  messageItem: IMessageModel;
  content: any;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'message-custom',
  props: {
    messageItem: { default: undefined },
    content: { default: undefined }
  },
  setup(__props: any) {

const props = __props;

const custom = ref();
const message = ref<IMessageModel>();
const extension = ref();
const isCustom = ref<ICustomMessagePayload>({
  businessID: '',
});

watchEffect(() => {
  custom.value = props.content;
  message.value = props.messageItem;
  const { payload } = props.messageItem;
  isCustom.value = payload.data || '';
  isCustom.value = JSONToObject(payload.data);
  if (payload.data === CHAT_MSG_CUSTOM_TYPE.SERVICE) {
    extension.value = JSONToObject(payload.extension);
  }
});
const openLink = (url: any) => {
  window.open(url);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_unref(isCustom).businessID === _unref(CHAT_MSG_CUSTOM_TYPE).SERVICE)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("h1", null, [
            _createElementVNode("label", null, _toDisplayString(_unref(extension).title), 1),
            (_unref(extension).hyperlinks_text)
              ? (_openBlock(), _createElementBlock("a", {
                  key: 0,
                  href: _unref(extension).hyperlinks_text.value,
                  target: "view_window"
                }, _toDisplayString(_unref(extension).hyperlinks_text.key), 9, _hoisted_3))
              : _createCommentVNode("", true)
          ]),
          (_unref(extension).item && _unref(extension).item.length > 0)
            ? (_openBlock(), _createElementBlock("ul", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(extension).item, (item, index) => {
                  return (_openBlock(), _createElementBlock("li", { key: index }, [
                    (_unref(isUrl)(item.value))
                      ? (_openBlock(), _createElementBlock("a", {
                          key: 0,
                          href: item.value,
                          target: "view_window"
                        }, _toDisplayString(item.key), 9, _hoisted_5))
                      : (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(item.key), 1))
                  ]))
                }), 128))
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("article", null, _toDisplayString(_unref(extension).description), 1)
        ]))
      : (_unref(isCustom).businessID === _unref(CHAT_MSG_CUSTOM_TYPE).EVALUATE)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createElementVNode("h1", null, _toDisplayString(_unref(TUITranslateService).t("message.custom.对本次服务评价")), 1),
            _createElementVNode("ul", _hoisted_8, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Math.max(_unref(isCustom).score, 0), (item, index) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: index,
                  class: "evaluate-list-item"
                }, [
                  _createVNode(Icon, {
                    file: _unref(star),
                    class: "file-icon"
                  }, null, 8, ["file"])
                ]))
              }), 128))
            ]),
            _createElementVNode("article", null, _toDisplayString(_unref(isCustom).comment), 1)
          ]))
        : (_unref(isCustom).businessID === _unref(CHAT_MSG_CUSTOM_TYPE).ORDER)
          ? (_openBlock(), _createElementBlock("div", {
              key: 2,
              class: "order",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (openLink(_unref(isCustom).link)))
            }, [
              _createElementVNode("img", {
                src: _unref(isCustom).imageUrl
              }, null, 8, _hoisted_9),
              _createElementVNode("main", null, [
                _createElementVNode("h1", null, _toDisplayString(_unref(isCustom).title), 1),
                _createElementVNode("p", null, _toDisplayString(_unref(isCustom).description), 1),
                _createElementVNode("span", null, _toDisplayString(_unref(isCustom).price), 1)
              ])
            ]))
          : (_unref(isCustom).businessID === _unref(CHAT_MSG_CUSTOM_TYPE).LINK)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                _createElementVNode("p", null, _toDisplayString(_unref(isCustom).text), 1),
                _createElementVNode("a", {
                  href: _unref(isCustom).link,
                  target: "view_window"
                }, _toDisplayString(_unref(TUITranslateService).t("message.custom.查看详情>>")), 9, _hoisted_11)
              ]))
            : (_openBlock(), _createElementBlock("span", {
                key: 4,
                innerHTML: _ctx.content.custom
              }, null, 8, _hoisted_12))
  ]))
}
}

})