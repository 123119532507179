import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "audio-icon-container" }
const _hoisted_2 = ["src"]

import { watchEffect, ref, onMounted, onUnmounted } from '../../../../adapter-vue';
import Icon from '../../../common/Icon.vue';
import audioIcon from '../../../../assets/icon/msg-audio.svg';
import { isMobile } from '../../../../utils/env';

interface IEmits {
  (e: 'setAudioPlayed', messageID: string): void;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'message-audio',
  props: {
  content: {
    type: Object,
    default: () => ({}),
  },
  messageItem: {
    type: Object,
    default: () => ({}),
  },
},
  emits: ["setAudioPlayed"],
  setup(__props, { emit: __emit }) {

const emits = __emit;
const props = __props;

const data = ref();
const message = ref();
const isAudioPlaying = ref();
const audioRef = ref<HTMLAudioElement>();

onMounted(() => {
  if (audioRef.value) {
    audioRef.value.addEventListener('ended', onAudioEnded);
    audioRef.value.addEventListener('pause', onAudioPaused);
  }
});

onUnmounted(() => {
  if (audioRef.value) {
    audioRef.value.removeEventListener('ended', onAudioEnded);
    audioRef.value.removeEventListener('pause', onAudioPaused);
  }
});

watchEffect(() => {
  message.value = props.messageItem;
  data.value = props.content;
});

function play() {
  if (message.value.hasRiskContent || !audioRef.value) {
    return;
  }
  if (!audioRef.value.paused) {
    audioRef.value.pause();
    audioRef.value.currentTime = 0;
    isAudioPlaying.value = false;
    return;
  }
  const audios = document.getElementsByTagName('audio');
  Array.from(audios).forEach((audio) => {
    if (!audio.paused) {
      audio.pause();
      audio.currentTime = 0;
    }
  });
  audioRef.value.play();
  isAudioPlaying.value = true;
  if (message.value.flow === 'in') {
    emits('setAudioPlayed', message.value.ID);
  }
}

function onAudioEnded() {
  isAudioPlaying.value = false;
}

function onAudioPaused() {
  isAudioPlaying.value = false;
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["message-audio", [
      _unref(isMobile) && 'message-audio-h5',
      _unref(message).flow === 'out' && 'reserve',
      _unref(message).hasRiskContent && 'disable',
    ]]),
    onClick: _withModifiers(play, ["stop"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: _normalizeClass({ 'mask': true, 'play': _unref(isAudioPlaying) })
      }, null, 2),
      _createVNode(Icon, {
        class: "icon",
        width: "16px",
        height: "20px",
        file: _unref(audioIcon)
      }, null, 8, ["file"])
    ]),
    _createElementVNode("span", {
      class: "time",
      style: _normalizeStyle({ width: `${_unref(data).second * 10 + 20}px` })
    }, _toDisplayString(_unref(data).second || 1) + " \" ", 5),
    _createElementVNode("audio", {
      ref_key: "audioRef",
      ref: audioRef,
      src: _unref(data).url
    }, null, 8, _hoisted_2)
  ], 2))
}
}

})