const TUIContact = {
  "群聊通知": "群聊通知",
  "系统通知": "系統通知",
  "我的群聊": "我的群聊",
  "输入ID": "請輸入用戶/群聊 ID，回車搜索",
  "群名称": "群名稱",
  "群ID": "群ID",
  "加入群聊": "加入群聊",
  "解散群聊": "解散群聊",
  "退出群聊": "退出群聊",
  "进入群聊": "進入群聊",
  "群类型": "群類型",
  "请填写验证信息": "請填寫驗證信息",
  "申请加入": "申請加入",
  "已申请": "已申請",
  "接受": "接受",
  "拒绝": "拒絕",
  "已处理": "已處理",
  "添加群聊": "添加群聊",
  "已加入": "已加入",
  "该群组不存在": "該群組不存在",
  "加群": "加群",
  "我的好友": "我的好友",
  "发送消息": "發送消息",
  "个性签名": "個性簽名",
  "等待管理员同意": "申請成功，等待管理員同意入群申請",
  "加群成功": "加群成功",
  "您已是群成员": "您已是群成員",
  "新的联系人": "新的聯繫人",
  "黑名单": "黑名單",
  "无搜索结果": "無搜索結果",
  "客服号": "客服號",
  "ID": "ID",
  "联系人": "聯繫人",
  "群聊": "群聊",
  "添加好友/群聊": "添加好友/群聊",
  "备注名": "備註名",
  "加入黑名单": "加入黑名單",
  "删除好友": "刪除好友",
  "等待验证": "等待驗證",
  "验证信息": "驗證信息",
  "发送申请": "發送申請",
  "加入直播群": "加入直播群",
  "同意": "同意",
  "删除好友成功": "刪除好友成功",
  "删除好友失败": "刪除好友失敗",
  "申请已发送": "申請已發送",
  "申请发送失败": "申請發送失敗",
  "进入会话失败": "進入會話失敗",
  "添加好友成功": "添加好友成功",
  "同意好友申请失败": "同意好友申請失敗",
  "拒绝成功": "拒絕成功",
  "拒绝好友申请失败": "拒絕好友申請失敗",
  "解散群聊成功": "解散群聊成功",
  "解散群聊失败": "解散群聊失敗",
  "退出群组成功": "退出群組成功",
  "退出群组失败": "退出群組失敗",
  "申请入群失败": "申請入群失敗",
  "加入黑名单失败": "加入黑名單失敗",
  "移除黑名单失败": "移除黑名單失敗",
  "修改备注失败: 备注长度不得超过 96 字节": "修改備註失敗: 備註長度不得超過 96 字節",
  "修改备注成功": "修改備註成功",
  "修改备注失败": "修改備註失敗",
}

export default TUIContact;
