import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers } from "vue"

import { computed, ref, onBeforeMount } from '../../../adapter-vue';
import dayjs, { Dayjs, ManipulateType } from 'dayjs';
import { TUITranslateService } from '@tencentcloud/chat-uikit-engine';
import { DateCell } from './date-picker';
import DateTable from './date-table.vue';
import Icon from '../Icon.vue';
import leftArrowIcon from '../../../assets/icon/left-arrow.svg';
import rightArrowIcon from '../../../assets/icon/right-arrow.svg';
import dLeftArrowIcon from '../../../assets/icon/d-left-arrow.svg';
import dRightArrowIcon from '../../../assets/icon/d-right-arrow.svg';
import { isPC } from '../../../utils/env';


export default /*@__PURE__*/_defineComponent({
  __name: 'date-picker-panel',
  props: {
  type: {
    type: String,
    default: 'range', // "single"/"range"
  },
  // Unique attribute when type is single
  date: {
    type: Dayjs,
    default: () => dayjs(),
  },
  // Unique attribute when type is range
  startDate: {
    type: Dayjs,
    default: null,
  },
  endDate: {
    type: Dayjs,
    default: null,
  },
  rangeType: {
    type: String,
    default: '', // "left"/"right"
  },
  currentOtherPanelValue: {
    type: Dayjs,
    default: null,
  },
},
  emits: ['pick', 'change'],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;
const n = (className: string) => {
  return className
    ? [
        'tui-date-picker-panel-' + className,
        !isPC && 'tui-date-picker-panel-h5-' + className,
      ]
    : ['tui-date-picker-panel', !isPC && 'tui-date-picker-panel-h5'];
};

const currentPanelDate = ref<typeof Dayjs>();
const year = computed(() => currentPanelDate.value?.get('year'));
const month = computed(() => currentPanelDate.value?.format('MMMM'));
const canYearMore = computed(() => {
  const prevYearNumber = props.currentOtherPanelValue?.year() - 1;
  const prevYear = props.currentOtherPanelValue?.year(prevYearNumber);
  return (
    props.rangeType === 'right'
    || currentPanelDate.value?.isBefore(prevYear, 'year')
  );
});
const canMonthMore = computed(() => {
  const prevMonthNumber = props.currentOtherPanelValue?.month() - 1;
  const prevMonth = props.currentOtherPanelValue?.month(prevMonthNumber);
  return (
    props.rangeType === 'right'
    || currentPanelDate.value?.isBefore(prevMonth, 'month')
  );
});
const canYearLess = computed(() => {
  const nextYearNumber = props.currentOtherPanelValue?.year() + 1;
  const nextYear = props.currentOtherPanelValue?.year(nextYearNumber);
  return (
    props.rangeType === 'left'
    || currentPanelDate.value?.isAfter(nextYear, 'year')
  );
});
const canMonthLess = computed(() => {
  const nextMonthNumber = props.currentOtherPanelValue?.month() + 1;
  const nextMonth = props.currentOtherPanelValue?.month(nextMonthNumber);
  return (
    props.rangeType === 'left'
    || currentPanelDate.value?.isAfter(nextMonth, 'month')
  );
});

// Range judgment:
// Premise: If there is only one, it must be the start.
// If there is a startDate:
// When the left side of the interface first displays the month/year of the startDate.
// If there is both a startDate and an endDate:
// If they are in the same month:
// Both are displayed on the left, and the next month is displayed on the right.
// If they are not in the same month:
// The start is displayed on the left, and the end is displayed on the right.
// That is, to determine whether the start and end are in the same month.
// If neither is present, the left displays the current month, and the right displays the next month.
const handleSingleDate = (): { date: typeof Dayjs } => {
  if (props.date && dayjs(props.date)?.isValid()) {
    // props.date year and month
    return {
      date: props?.date,
    };
  }
  // nowadays year and month
  return {
    date: dayjs(),
  };
};

const handleRangeDate = (): { date: typeof Dayjs } => {
  switch (props.rangeType) {
    case 'left':
      if (props.startDate && dayjs.isDayjs(props.startDate)) {
        return {
          date: props?.startDate,
        };
      } else {
        return {
          date: dayjs(),
        };
      }
    case 'right':
      if (
        props.endDate
        && dayjs.isDayjs(props.endDate)
        && props?.endDate?.isAfter(props.startDate, 'month')
      ) {
        return {
          date: props?.endDate,
        };
      } else {
        const _month = (props.startDate || dayjs()).month();
        return {
          date: (props.startDate || dayjs()).month(_month + 1),
        };
      }
    default:
      return {
        date: dayjs(),
      };
  }
};

function handlePick(cell: DateCell) {
  emit('pick', cell);
}

function change(type: typeof ManipulateType, num: number) {
  currentPanelDate.value = dayjs(currentPanelDate.value.toDate()).add(
    num,
    type,
  );
  emit('change', currentPanelDate.value);
}

onBeforeMount(() => {
  switch (props.type) {
    case 'single':
      currentPanelDate.value = handleSingleDate().date;
      emit('change', currentPanelDate.value);
      break;
    case 'range':
      currentPanelDate.value = handleRangeDate().date;
      emit('change', currentPanelDate.value);
      break;
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([n('')]),
    onMouseup: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["stop"]))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass([n('body')])
    }, [
      _createElementVNode("div", {
        class: _normalizeClass([n('body-header')])
      }, [
        _createElementVNode("div", {
          class: _normalizeClass([n('body-header-prev')])
        }, [
          (_unref(canYearLess))
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass([n('icon')]),
                onClick: _cache[0] || (_cache[0] = ($event: any) => (change('year', -1)))
              }, [
                _createVNode(Icon, {
                  file: _unref(dLeftArrowIcon),
                  width: '12px',
                  height: '12px'
                }, null, 8, ["file"])
              ], 2))
            : _createCommentVNode("", true),
          (_unref(canMonthLess))
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass([n('icon')]),
                onClick: _cache[1] || (_cache[1] = ($event: any) => (change('month', -1)))
              }, [
                _createVNode(Icon, {
                  file: _unref(leftArrowIcon),
                  width: '10px',
                  height: '10px'
                }, null, 8, ["file"])
              ], 2))
            : _createCommentVNode("", true)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass([n('body-header-label')])
        }, [
          _createElementVNode("div", {
            class: _normalizeClass([n('body-header-label-item')])
          }, _toDisplayString(_unref(year)), 3),
          _createElementVNode("div", {
            class: _normalizeClass([n('body-header-label-item')])
          }, _toDisplayString(_unref(TUITranslateService).t(`time.${_unref(month)}`)), 3)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass([n('body-header-next')])
        }, [
          (_unref(canMonthMore))
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass([n('icon')]),
                onClick: _cache[2] || (_cache[2] = ($event: any) => (change('month', 1)))
              }, [
                _createVNode(Icon, {
                  file: _unref(rightArrowIcon),
                  width: '10px',
                  height: '10px'
                }, null, 8, ["file"])
              ], 2))
            : _createCommentVNode("", true),
          (_unref(canYearMore))
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass([n('icon')]),
                onClick: _cache[3] || (_cache[3] = ($event: any) => (change('year', 1)))
              }, [
                _createVNode(Icon, {
                  file: _unref(dRightArrowIcon),
                  width: '12px',
                  height: '12px'
                }, null, 8, ["file"])
              ], 2))
            : _createCommentVNode("", true)
        ], 2)
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass([n('body-content')])
      }, [
        _createVNode(DateTable, {
          type: props.type,
          date: props.date,
          startDate: props.startDate,
          endDate: props.endDate,
          currentPanelDate: _unref(currentPanelDate),
          onPick: handlePick
        }, null, 8, ["type", "date", "startDate", "endDate", "currentPanelDate"])
      ], 2)
    ], 2)
  ], 34))
}
}

})