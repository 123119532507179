import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "message-plugin" }
const _hoisted_2 = {
  key: 0,
  class: "message-plugin-tip"
}

import { computed } from '../../adapter-vue';
import { TUIStore, IMessageModel } from '@tencentcloud/chat-uikit-engine';
import MessageBubble from '../../components/TUIChat/message-list/message-elements/message-bubble.vue';
import { isUniFrameWork } from '../../utils/env';

interface IProps {
  message: IMessageModel;
  showStyle: string;
  bubbleClassNameList?: string[];
  blinkMessageIDList?: string[];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'message-plugin-layout',
  props: {
    message: { default: () => ({} as IMessageModel) },
    showStyle: { default: '' },
    bubbleClassNameList: { default: () => [] as string[] },
    blinkMessageIDList: { default: () => [] as string[] }
  },
  emits: ['resendMessage', 'handleToggleMessageItem', 'handleH5LongPress'],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emits = __emit;
const messageModel = computed(() => TUIStore.getMessageModel(props.message?.ID));

// The following is for external interaction such as messageTool, no special processing is required, do not change
const resendMessage = (message: IMessageModel) => {
  emits('resendMessage', message);
};
const handleToggleMessageItem = (e: any, message: IMessageModel, isLongpress = false) => {
  emits('handleToggleMessageItem', e, message, isLongpress);
};
const handleH5LongPress = (e: any, message: IMessageModel, type: string) => {
  emits('handleH5LongPress', e, message, type);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (props.showStyle === 'tip')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "messageTip")
        ]))
      : (props.showStyle === 'bubble')
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "message-plugin-bubble-content",
            onLongpress: _cache[1] || (_cache[1] = ($event: any) => (handleToggleMessageItem($event, _unref(messageModel), true))),
            onContextmenu: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (handleToggleMessageItem($event, _unref(messageModel))), ["prevent","right"])),
            onTouchstart: _cache[3] || (_cache[3] = ($event: any) => (handleH5LongPress($event, _unref(messageModel), 'touchstart'))),
            onTouchend: _cache[4] || (_cache[4] = ($event: any) => (handleH5LongPress($event, _unref(messageModel), 'touchend'))),
            onMouseover: _cache[5] || (_cache[5] = ($event: any) => (handleH5LongPress($event, _unref(messageModel), 'touchend')))
          }, [
            _createVNode(MessageBubble, {
              messageItem: _unref(messageModel),
              content: _unref(messageModel).getMessageContent(),
              blinkMessageIDList: props.blinkMessageIDList,
              classNameList: props.bubbleClassNameList,
              onResendMessage: _cache[0] || (_cache[0] = ($event: any) => (resendMessage(_unref(messageModel))))
            }, {
              messageElement: _withCtx(() => [
                (!_unref(isUniFrameWork))
                  ? _renderSlot(_ctx.$slots, "messageBubble", { key: 0 })
                  : _createCommentVNode("", true)
              ]),
              default: _withCtx(() => [
                (_unref(isUniFrameWork))
                  ? _renderSlot(_ctx.$slots, "messageBubble", { key: 0 })
                  : _createCommentVNode("", true)
              ]),
              _: 3
            }, 8, ["messageItem", "content", "blinkMessageIDList", "classNameList"])
          ], 32))
        : _createCommentVNode("", true)
  ]))
}
}

})