import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import TUIChatEngine, {
  TUIChatService,
  TUIStore,
  StoreName,
  IConversationModel,
  SendMessageParams,
  SendMessageOptions,
} from '@tencentcloud/chat-uikit-engine';
import { ref } from '../../../../adapter-vue';
import ToolbarItemContainer from '../toolbar-item-container/index.vue';
import fileIconLight from '../../../../assets/icon/file-light.svg';
import fileIconDark from '../../../../assets/icon/file-dark.svg';
import { isPC, isUniFrameWork } from '../../../../utils/env';
import { isEnabledMessageReadReceiptGlobal } from '../../utils/utils';
import OfflinePushInfoManager, { IOfflinePushInfoCreateParams } from '../../offlinePushInfoManager/index';
import TUIChatConfig from '../../config';


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

const fileIcon = TUIChatConfig.getTheme() === 'dark' ? fileIconDark : fileIconLight;
const inputRef = ref();
const currentConversation = ref<IConversationModel>();

TUIStore.watch(StoreName.CONV, {
  currentConversation: (conversation: IConversationModel) => {
    currentConversation.value = conversation;
  },
});

const onIconClick = () => {
  if (isUniFrameWork) {
    return;
  } else {
    inputRef?.value?.click && inputRef?.value?.click();
  }
};

const sendFileMessage = (e: any) => {
  if (e?.target?.files?.length <= 0) {
    return;
  }
  const options = {
    to:
      currentConversation?.value?.groupProfile?.groupID
      || currentConversation?.value?.userProfile?.userID,
    conversationType: currentConversation?.value?.type,
    payload: {
      file: e?.target,
    },
    needReadReceipt: isEnabledMessageReadReceiptGlobal(),
  } as SendMessageParams;
  const offlinePushInfoCreateParams: IOfflinePushInfoCreateParams = {
    conversation: currentConversation.value,
    payload: options.payload,
    messageType: TUIChatEngine.TYPES.MSG_FILE,
  };
  const sendMessageOptions: SendMessageOptions = {
    offlinePushInfo: OfflinePushInfoManager.create(offlinePushInfoCreateParams),
  };
  TUIChatService.sendFileMessage(options, sendMessageOptions);
  e.target.value = '';
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(ToolbarItemContainer, {
    iconFile: _unref(fileIcon),
    title: "文件",
    iconWidth: _unref(isUniFrameWork) ? '32px' : '20px',
    iconHeight: _unref(isUniFrameWork) ? '25px' : '18px',
    needDialog: false,
    onOnIconClick: onIconClick
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(['file-upload', !_unref(isPC) && 'file-upload-h5'])
      }, [
        _createElementVNode("input", {
          ref_key: "inputRef",
          ref: inputRef,
          title: "文件",
          type: "file",
          "data-type": "file",
          accept: "*",
          onChange: sendFileMessage
        }, null, 544)
      ], 2)
    ]),
    _: 1
  }, 8, ["iconFile", "iconWidth", "iconHeight"]))
}
}

})