import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, isRef as _isRef, vModelText as _vModelText, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["placeholder", "disabled"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["placeholder", "disabled"]

import { ref, computed } from '../../../adapter-vue';
import { TUITranslateService } from '@tencentcloud/chat-uikit-engine';
// dayjs extension
import dayjs, { Dayjs } from 'dayjs';
import localeData from 'dayjs/plugin/localeData.js';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter.js';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore.js';
import 'dayjs/locale/zh-cn';
import DatePickerPanel from './date-picker-panel.vue';
import { DateCell } from './date-picker';
import { isPC, isUniFrameWork } from '../../../utils/env';


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
  type: {
    type: String,
    default: 'range', // "single" / "range"
  },
  rangeTableType: {
    type: String,
    default: 'one', // "one"/ "two"
  },
  startPlaceholder: {
    type: String,
    default: () => TUITranslateService.t('开始时间'),
  },
  endPlaceholder: {
    type: String,
    default: () => TUITranslateService.t('开始时间'),
  },
  popupPosition: {
    type: String,
    default: 'bottom', // "top" / "bottom"
  },
  // Default single-select date
  defaultSingleDate: {
    type: Dayjs,
    default: null,
    required: false,
  },
},
  emits: ['pick', 'change'],
  setup(__props, { emit: __emit }) {

dayjs.extend(localeData);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.locale('zh-cn');

const emit = __emit;

const props = __props;

const isDatePanelShow = ref<boolean>(false);

const dateValue = ref<typeof Dayjs>(props.type === 'single' ? props?.defaultSingleDate : null);
const startValue = ref<typeof Dayjs>(props.type === 'single' ? props?.defaultSingleDate : null);
const endValue = ref<typeof Dayjs>(props.type === 'single' ? props?.defaultSingleDate : null);
const startFormatDate = computed(() => startValue?.value?.format('YYYY/MM/DD'));
const endFormatDate = computed(() => endValue?.value?.format('YYYY/MM/DD'));
const startPlaceholderVal = props.startPlaceholder;
const endPlaceholderVal = props.endPlaceholder;
const leftCurrentPanelValue = ref<typeof Dayjs>();
const rightCurrentPanelValue = ref<typeof Dayjs>();

const setDatePanelDisplay = (show: boolean) => {
  isDatePanelShow.value = show;
};

const n = (classNameList: string[]) => {
  const resultClassList: string[] = [];
  classNameList.forEach((className: string) => {
    if (className) {
      resultClassList.push('tui-date-picker-' + className);
      !isPC && resultClassList.push('tui-date-picker-h5-' + className);
    } else {
      resultClassList.push('tui-date-picker');
      !isPC && resultClassList.push('tui-date-picker-h5');
    }
  });
  return resultClassList;
};

const handlePick = (cell: DateCell) => {
  switch (props.type) {
    case 'single':
      startValue.value = cell.date;
      endValue.value = cell.date;
      dateValue.value = cell.date;
      emit('change', cell);
      emit('pick', dateValue.value);
      setTimeout(() => {
        setDatePanelDisplay(false);
      }, 300);
      break;
    case 'range':
      if (!startValue?.value) {
        startValue.value = cell.date;
      } else if (!endValue?.value) {
        if (startValue?.value?.isSameOrBefore(cell.date, 'day')) {
          endValue.value = cell.date;
        } else {
          endValue.value = startValue.value;
          startValue.value = cell.date;
        }
        emit('pick', {
          startDate: startValue?.value?.startOf('date'),
          endDate: endValue?.value?.endOf('date'),
        });
        setTimeout(() => {
          setDatePanelDisplay(false);
        }, 200);
      } else {
        startValue.value = cell.date;
        endValue.value = null;
      }
      emit('change', {
        startDate: startValue.value,
        endDate: endValue.value,
        leftCurrentPanel: leftCurrentPanelValue.value,
        rightCurrentPanel: leftCurrentPanelValue.value,
      });
      break;
  }
};

const handleLeftPanelChange = (value: typeof Dayjs) => {
  leftCurrentPanelValue.value = value;
  emit('change', {
    startDate: startValue.value,
    endDate: endValue.value,
    leftCurrentPanel: leftCurrentPanelValue.value,
    rightCurrentPanel: leftCurrentPanelValue.value,
  });
};
const handleRightPanelChange = (value: typeof Dayjs) => {
  rightCurrentPanelValue.value = value;
  emit('change', {
    startDate: startValue.value,
    endDate: endValue.value,
    leftCurrentPanel: leftCurrentPanelValue.value,
    rightCurrentPanel: leftCurrentPanelValue.value,
  });
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([n([''])])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass([n(['input']), _unref(isDatePanelShow) && n(['input-active'])]),
      onClick: _cache[2] || (_cache[2] = ($event: any) => (setDatePanelDisplay(!_unref(isDatePanelShow))))
    }, [
      _renderSlot(_ctx.$slots, "start-icon"),
      _withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(startFormatDate) ? (startFormatDate).value = $event : null)),
        placeholder: _unref(startPlaceholderVal),
        class: _normalizeClass([n(['input-start'])]),
        style: {"pointer-events":"none"},
        type: "text",
        readonly: true,
        disabled: _unref(isUniFrameWork),
        autocomplete: "false"
      }, null, 10, _hoisted_1), [
        [_vModelText, _unref(startFormatDate)]
      ]),
      (__props.type !== 'single')
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, "-"))
        : _createCommentVNode("", true),
      (__props.type !== 'single')
        ? _withDirectives((_openBlock(), _createElementBlock("input", {
            key: 1,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(endFormatDate) ? (endFormatDate).value = $event : null)),
            placeholder: _unref(endPlaceholderVal),
            class: _normalizeClass([n(['input-end'])]),
            style: {"pointer-events":"none"},
            type: "text",
            readonly: true,
            disabled: _unref(isUniFrameWork),
            autocomplete: "false"
          }, null, 10, _hoisted_3)), [
            [_vModelText, _unref(endFormatDate)]
          ])
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "end-icon")
    ], 2),
    (_unref(isDatePanelShow))
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass([n(['dialog'])])
        }, [
          _createElementVNode("div", {
            class: _normalizeClass([
          n([
            'dialog-container',
            'dialog-container-' + __props.rangeTableType,
            'dialog-container-' + __props.popupPosition,
          ]),
        ])
          }, [
            _createVNode(DatePickerPanel, {
              type: props.type,
              rangeType: "left",
              date: _unref(dateValue),
              startDate: _unref(startValue),
              endDate: _unref(endValue),
              currentOtherPanelValue: _unref(rightCurrentPanelValue),
              onPick: handlePick,
              onChange: handleLeftPanelChange
            }, null, 8, ["type", "date", "startDate", "endDate", "currentOtherPanelValue"]),
            (props.type === 'range' && _unref(isPC) && __props.rangeTableType === 'two')
              ? (_openBlock(), _createBlock(DatePickerPanel, {
                  key: 0,
                  type: props.type,
                  rangeType: "right",
                  date: _unref(dateValue),
                  startDate: _unref(startValue),
                  endDate: _unref(endValue),
                  currentOtherPanelValue: _unref(leftCurrentPanelValue),
                  onPick: handlePick,
                  onChange: handleRightPanelChange
                }, null, 8, ["type", "date", "startDate", "endDate", "currentOtherPanelValue"]))
              : _createCommentVNode("", true)
          ], 2)
        ], 2))
      : _createCommentVNode("", true)
  ], 2))
}
}

})