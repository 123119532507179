import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "message-video" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src", "poster"]
const _hoisted_4 = ["src", "poster"]
const _hoisted_5 = {
  key: 0,
  class: "dialog-video"
}
const _hoisted_6 = ["src"]

import {
  ref,
  watch,
  computed,
  nextTick,
  watchEffect,
  withDefaults,
} from '../../../../adapter-vue';
import { IMessageModel } from '@tencentcloud/chat-uikit-engine';
import { handleSkeletonSize } from '../../utils/utils';
import Icon from '../../../common/Icon.vue';
import closeSVG from '../../../../assets/icon/icon-close.svg';
import type { IVideoMessageContent } from '../../../../interface';
import { isPC } from '../../../../utils/env';

const transparentPosterUrl = 'https://web.sdk.qcloud.com/im/assets/images/transparent.png';


export default /*@__PURE__*/_defineComponent({
  __name: 'message-video',
  props: {
    content: { default: () => ({} as IVideoMessageContent) },
    messageItem: { default: () => ({} as IMessageModel) }
  },
  emits: ['uploading'],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emits = __emit;
const isShow = ref(false);
const poster = ref('');
const posterWidth = ref(0);
const posterHeight = ref(0);
const skeleton = ref();
const videoRef = ref();
watchEffect(async () => {
  if (!props.content) return;
  poster.value = await handlePosterUrl(props.content, props.messageItem);
  nextTick(async () => {
    const containerWidth
      = document.getElementById('messageScrollList')?.clientWidth || 0;
    const max = !isPC ? Math.min(containerWidth - 172, 300) : 300;
    let size;
    if (props.messageItem.status === 'success') {
      let { snapshotWidth = 0, snapshotHeight = 0 } = props.content;
      const { snapshotUrl } = props.content;
      if (snapshotWidth === 0 || snapshotHeight === 0) return;
      if (snapshotUrl === transparentPosterUrl) {
        snapshotWidth = posterWidth.value;
        snapshotHeight = posterHeight.value;
      }
      size = handleSkeletonSize(snapshotWidth, snapshotHeight, max, max);
      skeleton?.value?.style
      && (skeleton.value.style.width = `${size.width}px`);
      skeleton?.value?.style
      && (skeleton.value.style.height = `${size.height}px`);
      if (isPC) {
        videoRef?.value?.style && (videoRef.value.style.width = `${size.width}px`);
        videoRef?.value?.style && (videoRef.value.style.height = `${size.height}px`);
      }
    } else {
      emits('uploading');
    }
  });
});

const isWidth = computed(() => {
  const { snapshotWidth = 0, snapshotHeight = 0 } = props.messageItem.payload;
  return snapshotWidth >= snapshotHeight;
});

watch(() => props.messageItem.status, (newVal: string, oldVal: string) => {
  if (newVal === 'success' && oldVal !== 'success') {
    emits('uploading');
  }
});

function toggleVideoPreviewer() {
  // Video upload process does not support full-screen playback.
  if (props.messageItem.progress > 0 && props.messageItem.progress < 1) {
    return;
  }
  isShow.value = !isShow.value;
}

// For H5, some browsers (Safari/WeChat) have an issue where the cover image of the<video>` tag is empty before the video is fully uploaded.
//  A separate processing and clipping method is required to display the cover image before the video upload is complete.
function getVideoBase64(url: string) {
  return new Promise((resolve) => {
    let dataURL = '';
    const video = document.createElement('video');
    video.setAttribute('crossOrigin', 'anonymous');
    video.setAttribute('src', url);
    video.setAttribute('preload', 'auto');
    video.addEventListener(
      'loadeddata',
      function () {
        const canvas = document.createElement('canvas'),
          width = video.videoWidth,
          height = video.videoHeight;
        canvas.width = width;
        canvas.height = height;
        (canvas as any).getContext('2d').drawImage(video, 0, 0, width, height);
        dataURL = canvas.toDataURL('image/jpeg');
        posterWidth.value = width;
        posterHeight.value = height;
        resolve(dataURL);
      },
      { once: true },
    );
  });
}

async function handlePosterUrl(messgeContent: IVideoMessageContent, messageItem: IMessageModel) {
  if (!messageItem) return '';
  if (messageItem.status !== 'success') {
    return await getVideoBase64(messgeContent.url);
  } else {
    return (
      (messgeContent.snapshotUrl !== transparentPosterUrl && messgeContent.snapshotUrl)
      || (messageItem?.payload?.snapshotUrl !== transparentPosterUrl
      && messageItem?.payload?.snapshotUrl)
      || (messageItem.payload?.thumbUrl !== transparentPosterUrl
      && messageItem?.payload?.thumbUrl)
      || (await getVideoBase64(messgeContent.url))
    );
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      ref_key: "skeleton",
      ref: skeleton,
      class: _normalizeClass(["message-video-box", [
        (!props.messageItem.progress || props.messageItem.progress === 1)
          && !_unref(isPC)
          && 'message-video-cover',
      ]]),
      onClick: toggleVideoPreviewer
    }, [
      ((props.messageItem.progress > 0 && props.messageItem.progress < 1 && _unref(poster)) ||
          (!_unref(isPC) && _unref(poster))
        )
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            class: _normalizeClass(["message-img", [_unref(isWidth) ? 'is-width' : 'is-height']]),
            src: _unref(poster)
          }, null, 10, _hoisted_2))
        : (!_unref(isPC))
          ? (_openBlock(), _createElementBlock("video", {
              key: 1,
              ref_key: "videoRef",
              ref: videoRef,
              class: "message-img video-h5-uploading",
              src: props.content.url + '#t=0.1',
              poster: props.content.url,
              preload: "auto",
              muted: ""
            }, null, 8, _hoisted_3))
          : (_openBlock(), _createElementBlock("video", {
              key: 2,
              ref_key: "videoRef",
              ref: videoRef,
              class: "message-img video-web",
              src: props.content.url,
              controls: "",
              preload: "metadata",
              poster: _unref(poster)
            }, null, 8, _hoisted_4))
    ], 2),
    (_unref(isShow) && !_unref(isPC))
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", {
            class: "dialog-video-close",
            onClick: _withModifiers(toggleVideoPreviewer, ["stop"])
          }, [
            _createVNode(Icon, { file: _unref(closeSVG) }, null, 8, ["file"])
          ]),
          _createElementVNode("div", {
            class: _normalizeClass(["dialog-video-box", [!_unref(isPC) ? 'dialog-video-h5' : '']]),
            onClick: _withModifiers(toggleVideoPreviewer, ["self"])
          }, [
            _createElementVNode("video", {
              class: _normalizeClass([_unref(isWidth) ? 'is-width' : 'is-height']),
              src: props.content.url,
              controls: "",
              autoplay: ""
            }, null, 10, _hoisted_6)
          ], 2)
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})