import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src", "width", "height"]

import { ref, watch, onMounted, withDefaults } from '../../../../adapter-vue';
import { IMessageModel } from '@tencentcloud/chat-uikit-engine';
import { isPC } from '../../../../utils/env';
import { handleSkeletonSize } from '../../utils/utils';
import type { IImageMessageContent } from '../../../../interface';


export default /*@__PURE__*/_defineComponent({
  __name: 'message-image',
  props: {
    content: { default: () => ({}) },
    messageItem: { default: () => ({} as IMessageModel) }
  },
  emits: ['previewImage'],
  setup(__props: any, { emit: __emit }) {

const emits = __emit;
const props = __props;
const skeletonDomRef = ref();

onMounted(() => {
  if (
    props.messageItem?.status === 'success'
    || props.messageItem?.status === 'fail'
    || props.messageItem?.progress === 1
  ) {
    autoFixSkeletonSize();
  }
});

watch(
  () => props.content.height,
  (newVal: number, oldVal: number) => {
    if (newVal > oldVal) {
      autoFixSkeletonSize();
    }
  },
);

function autoFixSkeletonSize() {
  const { width = 0, height = 0 } = props.content;
  if (width === 0 || height === 0) return;
  const containerWidth = document.getElementById('app')?.clientWidth || 0;
  const max = !isPC ? Math.min(containerWidth - 180, 300) : 300;
  const size = handleSkeletonSize(width, height, max, max);
  skeletonDomRef?.value?.style && (skeletonDomRef.value.style.width = `${size.width}px`);
  skeletonDomRef?.value?.style && (skeletonDomRef.value.style.height = `${size.height}px`);
}

function toggleShow() {
  if (props.messageItem?.status === 'success' || props.messageItem?.progress === 1) {
    emits('previewImage', props.messageItem);
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    ref_key: "skeletonDomRef",
    ref: skeletonDomRef,
    class: "image-container",
    onClick: _withModifiers(toggleShow, ["self"])
  }, [
    _createElementVNode("img", {
      class: _normalizeClass(['message-image', !_unref(isPC) && 'message-image-h5']),
      src: props.content.url,
      width: props.content.width,
      height: props.content.height
    }, null, 10, _hoisted_1)
  ], 512))
}
}

})