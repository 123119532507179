import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = ["src"]

import { withDefaults, computed } from '../../adapter-vue';
import { isApp, isPC } from '../../utils/env';

interface IProps {
  file: string;
  size?: string;
  width?: string;
  height?: string;
  hotAreaSize?: number | string;
}

interface IEmits {
  (key: 'onClick', e: Event): void;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Icon',
  props: {
    file: { default: '' },
    size: {},
    width: { default: '20px' },
    height: { default: '20px' },
    hotAreaSize: {}
  },
  emits: ["onClick"],
  setup(__props: any, { emit: __emit }) {

const emits = __emit;
const props = __props;

const iconHotAreaSize = computed<undefined | string>(() => {
  if (!props.hotAreaSize) {
    return undefined;
  }
  if (isNaN(Number(props.hotAreaSize))) {
    return String(props.hotAreaSize);
  }
  return `${props.hotAreaSize}px`;
});

const iconWidth = computed(() => {
  return props.size ? props.size : props.width;
});

const iconHeight = computed(() => {
  return props.size ? props.size : props.height;
});

const handleImgClick = (e: Event) => {
  emits('onClick', e);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['common-icon-container', !_unref(isPC) && 'common-icon-container-mobile']),
    style: _normalizeStyle({
      padding: _unref(iconHotAreaSize),
    }),
    onClick: handleImgClick
  }, [
    (_unref(isApp))
      ? (_openBlock(), _createElementBlock("image", {
          key: 0,
          class: "common-icon",
          src: props.file,
          style: _normalizeStyle({ width: _unref(iconWidth), height: _unref(iconHeight) })
        }, null, 12, _hoisted_1))
      : (_openBlock(), _createElementBlock("img", {
          key: 1,
          class: "common-icon",
          src: props.file,
          style: _normalizeStyle({ width: _unref(iconWidth), height: _unref(iconHeight) })
        }, null, 12, _hoisted_2))
  ], 6))
}
}

})