import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, isRef as _isRef, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "notification"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 2 }
const _hoisted_6 = {
  key: 1,
  class: "edit-h5"
}
const _hoisted_7 = { class: "edit-h5-main" }
const _hoisted_8 = { class: "edit-h5-header" }
const _hoisted_9 = { class: "left" }
const _hoisted_10 = { class: "notification" }
const _hoisted_11 = {
  key: 1,
  class: "row"
}
const _hoisted_12 = {
  key: 0,
  class: "row-p"
}
const _hoisted_13 = { key: 1 }
const _hoisted_14 = {
  key: 2,
  class: "footer"
}

import { nextTick } from '../../../adapter-vue';
import {
  TUITranslateService,
  IGroupModel,
} from '@tencentcloud/chat-uikit-engine';
import { watchEffect, ref } from '../../../adapter-vue';
import { Toast, TOAST_TYPE } from '../../common/Toast/index';
import { isUniFrameWork } from '../../../utils/env';


export default /*@__PURE__*/_defineComponent({
  __name: 'manage-notification',
  props: {
  data: {
    type: Object,
    default: () => ({}),
  },
  isAuthor: {
    type: Boolean,
    default: false,
  },
},
  emits: ['update', 'close'],
  setup(__props, { emit: __emit }) {

const props = __props;

const groupProfile = ref<IGroupModel>({});
const input = ref('');
const isAuthorNotification = ref(false);
const isEdit = ref(false);

watchEffect(() => {
  groupProfile.value = props.data;
  input.value = groupProfile.value.notification;
  isAuthorNotification.value = props.isAuthor;
});

const emits = __emit;

const updateProfile = () => {
  if (input.value.length > 150) {
    Toast({
      message: TUITranslateService.t('TUIGroup.群公告字数超出限制，最大长度为150'),
      type: TOAST_TYPE.ERROR,
    });
    return;
  }
  if (input.value && input.value !== groupProfile.value.notification) {
    emits('update', { key: 'notification', value: input.value });
    nextTick(() => {
      input.value = '';
    });
  }
  isEdit.value = !isEdit.value;
};

const close = (tabName: string) => {
  emits('close', tabName);
};

return (_ctx: any,_cache: any) => {
  return (!_unref(isUniFrameWork))
    ? (_openBlock(), _createElementBlock("main", _hoisted_1, [
        (_unref(isEdit))
          ? _withDirectives((_openBlock(), _createElementBlock("textarea", {
              key: 0,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(input) ? (input).value = $event : null)),
              class: "textarea",
              onKeyup: _withKeys(updateProfile, ["enter"])
            }, null, 544)), [
              [_vModelText, _unref(input)]
            ])
          : (_openBlock(), _createElementBlock("section", _hoisted_2, [
              (!_unref(groupProfile).notification)
                ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_unref(TUITranslateService).t(`TUIGroup.暂无公告`)), 1))
                : (_openBlock(), _createElementBlock("article", _hoisted_4, _toDisplayString(_unref(groupProfile).notification), 1))
            ])),
        (_unref(isAuthorNotification))
          ? (_openBlock(), _createElementBlock("footer", _hoisted_5, [
              (_unref(isEdit))
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    class: "btn",
                    onClick: updateProfile
                  }, _toDisplayString(_unref(TUITranslateService).t(`TUIGroup.发布`)), 1))
                : (_openBlock(), _createElementBlock("button", {
                    key: 1,
                    class: "btn",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (isEdit.value = !_unref(isEdit)))
                  }, _toDisplayString(_unref(TUITranslateService).t(`TUIGroup.编辑`)), 1))
            ]))
          : _createCommentVNode("", true)
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_6, [
        _createElementVNode("main", _hoisted_7, [
          _createElementVNode("header", _hoisted_8, [
            _createElementVNode("aside", _hoisted_9, [
              _createElementVNode("h1", null, _toDisplayString(_unref(TUITranslateService).t(`TUIGroup.群公告`)), 1)
            ]),
            _createElementVNode("span", {
              class: "close",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (close('notification')))
            }, _toDisplayString(_unref(TUITranslateService).t(`关闭`)), 1)
          ]),
          _createElementVNode("div", _hoisted_10, [
            (_unref(isEdit))
              ? _withDirectives((_openBlock(), _createElementBlock("textarea", {
                  key: 0,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_isRef(input) ? (input).value = $event : null)),
                  class: _normalizeClass([_unref(isUniFrameWork) ? 'uni-height' : '', 'textarea']),
                  onKeyup: _withKeys(updateProfile, ["enter"])
                }, null, 34)), [
                  [_vModelText, _unref(input)]
                ])
              : (_openBlock(), _createElementBlock("section", _hoisted_11, [
                  (!_unref(groupProfile).notification)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_12, _toDisplayString(_unref(TUITranslateService).t(`TUIGroup.暂无公告`)), 1))
                    : (_openBlock(), _createElementBlock("article", _hoisted_13, _toDisplayString(_unref(groupProfile).notification), 1))
                ])),
            (_unref(isAuthorNotification))
              ? (_openBlock(), _createElementBlock("footer", _hoisted_14, [
                  (_unref(isEdit))
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 0,
                        class: "btn",
                        onClick: updateProfile
                      }, _toDisplayString(_unref(TUITranslateService).t(`TUIGroup.发布`)), 1))
                    : (_openBlock(), _createElementBlock("button", {
                        key: 1,
                        class: "btn",
                        onClick: _cache[4] || (_cache[4] = ($event: any) => (isEdit.value = !_unref(isEdit)))
                      }, _toDisplayString(_unref(TUITranslateService).t(`TUIGroup.编辑`)), 1))
                ]))
              : _createCommentVNode("", true)
          ])
        ])
      ]))
}
}

})