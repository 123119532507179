import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "radio-no-select"
}

import Icon from '../Icon.vue';
import radioIcon from '../../../assets/icon/radio.svg';

interface IProps {
  isSelected: boolean;
}

interface IEmits {
  (e: 'onChange', value: boolean): void;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
    isSelected: { type: Boolean }
  },
  emits: ["onChange"],
  setup(__props: any, { emit: __emit }) {

const emits = __emit;
const props = __props;

function toggleSelect() {
  emits('onChange', !props.isSelected);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "radio-select",
    onClick: toggleSelect
  }, [
    (!props.isSelected)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1))
      : (_openBlock(), _createBlock(Icon, {
          key: 1,
          file: _unref(radioIcon),
          size: '20px'
        }, null, 8, ["file"]))
  ]))
}
}

})