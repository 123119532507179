import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, isRef as _isRef, vModelText as _vModelText, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "group-name" }
const _hoisted_2 = { class: "edit-h5-main" }
const _hoisted_3 = {
  key: 0,
  class: "edit-h5-header"
}
const _hoisted_4 = { class: "left" }
const _hoisted_5 = { class: "input-box" }
const _hoisted_6 = {
  key: 1,
  class: "tip"
}
const _hoisted_7 = {
  key: 1,
  class: "edit-h5-footer"
}

import { watchEffect, ref, nextTick, watch } from '../../../adapter-vue';
import {
  TUITranslateService,
  IGroupModel,
} from '@tencentcloud/chat-uikit-engine';
import Icon from '../../common/Icon.vue';
import editIcon from '../../../assets/icon/edit.svg';
import { Toast, TOAST_TYPE } from '../../common/Toast/index';
import { isMobile, isPC } from '../../../utils/env';


export default /*@__PURE__*/_defineComponent({
  __name: 'manage-name',
  props: {
  data: {
    type: Object,
    default: () => ({}),
  },
  isAuthor: {
    type: Boolean,
    default: false,
  },
},
  emits: ['update'],
  setup(__props, { emit: __emit }) {

const props = __props;

const groupProfile = ref<IGroupModel>({});
const inputGroupName = ref('');
const isEdit = ref(false);
const nameInputRef = ref<HTMLInputElement>(null);

watchEffect(() => {
  groupProfile.value = props.data;
});

const emit = __emit;
const updateProfile = () => {
  if (!inputGroupName.value) {
    Toast({
      message: TUITranslateService.t('TUIGroup.群名称不能为空'),
      type: TOAST_TYPE.ERROR,
    });
  } else {
    if (inputGroupName.value !== groupProfile.value.name) {
      emit('update', { key: 'name', value: inputGroupName.value });
      groupProfile.value.name = inputGroupName.value;
      inputGroupName.value = '';
      Toast({
        message: TUITranslateService.t('TUIGroup.群名称修改成功'),
        type: TOAST_TYPE.SUCCESS,
      });
    }
    toggleEditStatus();
  }
};

const toggleEditStatus = () => {
  if (props.isAuthor) {
    isEdit.value = !isEdit.value;
  }
  if (isEdit.value) {
    inputGroupName.value = groupProfile.value.name;
  }
};

watch(
  () => isEdit.value,
  (newVal: boolean) => {
    if (newVal) {
      nextTick(() => {
        nameInputRef.value?.focus();
      });
    }
  },
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", null, _toDisplayString(_unref(TUITranslateService).t(`TUIGroup.群名称`)), 1),
    (_unref(isEdit))
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass({
        'edit-h5': _unref(isMobile),
      })
        }, [
          _createElementVNode("main", _hoisted_2, [
            (!_unref(isPC))
              ? (_openBlock(), _createElementBlock("header", _hoisted_3, [
                  _createElementVNode("aside", _hoisted_4, [
                    _createElementVNode("h1", null, _toDisplayString(_unref(TUITranslateService).t(`TUIGroup.修改群聊名称`)), 1),
                    _createElementVNode("span", null, _toDisplayString(_unref(TUITranslateService).t(
                `TUIGroup.修改群聊名称后，将在群内通知其他成员`
              )), 1)
                  ]),
                  _createElementVNode("span", {
                    class: "close",
                    onClick: toggleEditStatus
                  }, _toDisplayString(_unref(TUITranslateService).t(`关闭`)), 1)
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_5, [
              (_unref(isEdit))
                ? _withDirectives((_openBlock(), _createElementBlock("input", {
                    key: 0,
                    ref_key: "nameInputRef",
                    ref: nameInputRef,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(inputGroupName) ? (inputGroupName).value = $event : null)),
                    class: "input",
                    type: "text",
                    onBlur: updateProfile
                  }, null, 544)), [
                    [_vModelText, _unref(inputGroupName)]
                  ])
                : _createCommentVNode("", true),
              (!_unref(isPC))
                ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_unref(TUITranslateService).t(
              `TUIGroup.仅限中文、字母、数字和下划线，2-20个字`
            )), 1))
                : _createCommentVNode("", true)
            ]),
            (!_unref(isPC))
              ? (_openBlock(), _createElementBlock("footer", _hoisted_7, [
                  _createElementVNode("button", {
                    class: "btn",
                    onClick: updateProfile
                  }, _toDisplayString(_unref(TUITranslateService).t(`确认`)), 1)
                ]))
              : _createCommentVNode("", true)
          ])
        ], 2))
      : _createCommentVNode("", true),
    (!_unref(isEdit) || !_unref(isPC))
      ? (_openBlock(), _createElementBlock("p", {
          key: 1,
          class: "name",
          onClick: toggleEditStatus
        }, [
          _createElementVNode("span", null, _toDisplayString(_unref(groupProfile).name), 1),
          (__props.isAuthor)
            ? (_openBlock(), _createBlock(Icon, {
                key: 0,
                class: "icon",
                file: _unref(editIcon),
                width: "14px",
                height: "14px"
              }, null, 8, ["file"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})