import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "message-timestamp"
}

import { toRefs, ref, watch } from '../../../../adapter-vue';
import { calculateTimestamp } from '../../utils/utils';


export default /*@__PURE__*/_defineComponent({
  __name: 'message-timestamp',
  props: {
  currTime: {
    type: Number,
    default: 0,
  },
  prevTime: {
    type: Number,
    default: 0,
  },
},
  setup(__props) {

const props = __props;
const { currTime, prevTime } = toRefs(props);
const timestampShowFlag = ref(false);
const timestampShowContent = ref('');

const handleItemTime = (currTime: number, prevTime: number) => {
  timestampShowFlag.value = false;
  if (currTime <= 0) {
    return '';
  } else if (!prevTime || prevTime <= 0) {
    timestampShowFlag.value = true;
    return calculateTimestamp(currTime * 1000);
  } else {
    const minDiffToShow = 10 * 60; // 10min 10*60s
    const diff = currTime - prevTime; // s
    if (diff >= minDiffToShow) {
      timestampShowFlag.value = true;
      return calculateTimestamp(currTime * 1000);
    }
  }
  return '';
};

watch(
  () => [currTime.value, prevTime.value],
  (newVal: any, oldVal: any) => {
    if (newVal?.toString() === oldVal?.toString()) {
      return;
    } else {
      timestampShowContent.value = handleItemTime(
        currTime.value,
        prevTime.value,
      );
    }
  },
  {
    immediate: true,
  },
);

return (_ctx: any,_cache: any) => {
  return (_unref(timestampShowFlag))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_unref(timestampShowContent)), 1))
    : _createCommentVNode("", true)
}
}

})