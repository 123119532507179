import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "drawer-container" }

import { ref, watch } from '../../../adapter-vue';
import Overlay from '../../common/Overlay/index.vue';

interface IProps {
  visible: boolean;
  popDirection: 'top' | 'right' | 'bottom' | 'left';
  useMask?: boolean;
  isFullScreen?: boolean | undefined;
  overlayColor?: string | undefined;
  drawerStyle?: {
    bottom?: Record<string, any> | undefined;
    right?: Record<string, any> | undefined;
    left?: Record<string, any> | undefined;
    top?: Record<string, any> | undefined;
  };
}

interface IEmits {
  (e: 'onOverlayClick', event: Event): void;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
    visible: { type: Boolean, default: true },
    popDirection: { default: 'bottom' },
    useMask: { type: Boolean, default: true },
    isFullScreen: { type: Boolean, default: true },
    overlayColor: {},
    drawerStyle: { default: () => ({}) }
  },
  emits: ["onOverlayClick"],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const emits = __emit;
const props = __props;

const drawerDomRef = ref<HTMLElement>();
const overlayDomInstanceRef = ref<InstanceType<typeof Overlay>>();
const isDrawerShow = ref<boolean>(false);

const styles = ref(props.drawerStyle[props.popDirection] || {});

watch(() => props.visible, (visible: boolean) => {
  if (visible) {
    isDrawerShow.value = true;
  } else {
    setTimeout(() => {
      isDrawerShow.value = false;
    }, 150);
  }
}, {
  immediate: true,
});

function onOverlayClick(e: Event) {
  emits('onOverlayClick', e);
}

__expose({
  drawerDomRef,
  overlayDomRef: overlayDomInstanceRef.value?.overlayDomRef,
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Overlay, {
    ref_key: "overlayDomInstanceRef",
    ref: overlayDomInstanceRef,
    visible: props.visible,
    useMask: props.useMask,
    maskColor: props.overlayColor,
    isFullScreen: props.isFullScreen,
    onOnOverlayClick: onOverlayClick
  }, {
    default: _withCtx(() => [
      (_unref(isDrawerShow))
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            ref_key: "drawerDomRef",
            ref: drawerDomRef,
            class: _normalizeClass({
        'drawer': true,
        'origin-bottom': props.popDirection === 'bottom',
        'origin-right': props.popDirection === 'right',
        'slide-bottom': _ctx.visible && props.popDirection === 'bottom',
        'slide-right': _ctx.visible && props.popDirection === 'right',
      }),
            style: _normalizeStyle({
        minHeight: _unref(styles).minHeight,
        maxHeight: _unref(styles).maxHeight,
        borderRadius: _unref(styles).borderRadius,
        boxShadow: _unref(styles).boxShadow,
        width: _unref(styles).width,
      })
          }, [
            _createElementVNode("div", _hoisted_1, [
              _renderSlot(_ctx.$slots, "default")
            ])
          ], 6))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 8, ["visible", "useMask", "maskColor", "isFullScreen"]))
}
}

})