import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "progress-message" }
const _hoisted_2 = {
  key: 0,
  class: "progress-container"
}
const _hoisted_3 = ["value"]
const _hoisted_4 = ["percent"]

import type { IMessageModel } from '@tencentcloud/chat-uikit-engine';
import { withDefaults } from '../../../adapter-vue';
import { isUniFrameWork } from '../../../utils/env';
import type { IImageMessageContent } from '../../../interface';


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
    content: { default: () => ({}) },
    messageItem: { default: () => ({} as IMessageModel) }
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "default"),
    (props.messageItem.status === 'unSend' && props.messageItem.progress < 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (!_unref(isUniFrameWork))
            ? (_openBlock(), _createElementBlock("progress", {
                key: 0,
                class: "progress",
                value: props.messageItem.progress,
                max: "1"
              }, null, 8, _hoisted_3))
            : (_openBlock(), _createElementBlock("progress", {
                key: 1,
                activeColor: "#006EFF",
                class: "progress-common",
                percent: Math.round(props.messageItem.progress*100)
              }, null, 8, _hoisted_4))
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})