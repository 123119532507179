const TUIGroup = {
  "输入groupID搜索": "輸入groupID搜索",
  "群名称": "群名稱",
  "群ID": "群ID",
  "加入群聊": "加入群聊",
  "解散群聊": "解散群聊",
  "退出群聊": "退出群聊",
  "群类型": "群類型",
  "请填写验证信息": "請填寫驗證信息",
  "申请加入": "申請加入",
  "已申请": "已申請",
  "群公告": "群公告",
  "群成员": "群成員",
  "群管理": "群管理",
  "好友工作群": "好友工作群",
  "陌生人社交群": "陌生人社交群",
  "临时会议群": "臨時會議群",
  "直播群": "直播群",
  "社群": "社群",
  "自由加入": "自由加入",
  "需要验证": "需要驗證",
  "禁止加群": "禁止加群",
  "人": "人",
  "群头像": "群頭像",
  "加群方式": "加群方式",
  "转让群组": "轉讓群組",
  "退出群组": "退出群組",
  "群管理员": "群管理員",
  "全员禁言": "全員禁言",
  "全员禁言开启后，只允许群主和管理员发言。": "全員禁言開啟後，只允許群主和管理員發言。",
  "单独禁言人员": "單獨禁言人員",
  "删除成员": "刪除成員",
  "确定从群聊中删除该成员？": "確定從群聊中刪除該成員？",
  "确定从群聊中删除所选成员？": "確定從群聊中刪除所選成員？",
  "暂无公告": "暫無公告",
  "发布": "發布",
  "编辑": "編輯",
  "查看更多": "查看更多",
  "管理员": "管理員",
  "群主": "群主",
  "我": "我",
  "确认": "確認",
  "添加成员": "添加成員",
  "新增管理员": "新增管理員",
  "移除管理员": "移除管理員",
  "新增禁言用户": "新增禁言用戶",
  "移除禁言用户": "移除禁言用戶",
  "修改群聊名称": "修改群聊名稱",
  "修改群聊名称后，将在群内通知其他成员": "修改群聊名稱後，將在群內通知其他成員",
  "仅限中文、字母、数字和下划线，2-20个字": "僅限中文、字母、數字和下劃線，2-20個字",
  "请先注册 TUIGroup 模块": "請先註冊 TUIGroup 模塊",
  "该用户不存在": "該用戶不存在",
  "该用户不在群组内": "該用戶不在群組內",
  "添加群聊": "添加群聊",
  "该群组不存在": "該群組不存在",
  "创建群聊，请注册 TUIGroup 模块": "創建群聊，請註冊 TUIGroup 模塊",
  "创建成功": "創建成功",
  "发起多人会话（群聊）": "發起多人會話（群聊）",
  "选填": "選填",
  "取消": "取消",
  "创建": "創建",
  "类似普通微信群，创建后仅支持已在群内的好友邀请加群，且无需被邀请方同意或群主审批。详见": "類似普通微信群，創建後僅支持已在群內的好友邀請加群，且無需被邀請方同意或群主審批。詳見",
  "类似 QQ 群，创建后群主可以指定群管理员，用户搜索群 ID 发起加群申请后，需要群主或管理员审批通过才能入群。详见": "類似 QQ 群，創建後群主可以指定群管理員，用戶搜索群 ID 發起加群申請後，需要群主或管理員審批通過才能入群。詳見",
  "创建后可以随意进出，且支持查看入群前消息；适合用于音视频会议场景、在线教育场景等与实时音视频产品结合的场景。详见": "創建後可以隨意進出，且支持查看入群前消息；適合用於音視頻會議場景、在線教育場景等與實時音視頻產品結合的場景。詳見",
  "创建后可以随意进出，没有群成员数量上限，但不支持历史消息存储；适合与直播产品结合，用于弹幕聊天场景。详见": "創建後可以隨意進出，沒有群成員數量上限，但不支持歷史消息存儲；適合與直播產品結合，用於彈幕聊天場景。詳見",
  "创建后可以随意进出，最多支持100000人，支持历史消息存储，用户搜索群 ID 发起加群申请后，无需管理员审批即可进群。详见": "創建後可以隨意進出，最多支持100000人，支持歷史消息存儲，用戶搜索群 ID 發起加群申請後，無需管理員審批即可進群。詳見",
  "产品文档": "產品文檔",
  "设置群名称": "設置群名稱",
  "请输入群名称": "請輸入群名稱",
  "设置群ID": "設置群ID",
  "请输入群ID": "請輸入群ID",
  "选择群类型": "選擇群類型",
  "陌生人社交群（Public）": "陌生人社交群（Public）",
  "临时会议群（Meeting）": "臨時會議群（Meeting）",
  "好友工作群（Work）": "好友工作群（Work）",
  "直播群（AVChatroom）": "直播群（AVChatroom）",
  "社群（Community）": "社群（Community）",
  "群组创建成功": "群組創建成功",
  "群组解散成功": "群組解散成功",
  "禁言设置成功": "禁言設置成功",
  "取消禁言成功": "取消禁言成功",
  "群名称不能为空": "群名稱不能為空",
  "群名称修改成功": "群名稱修改成功",
  "群公告字数超出限制，最大长度为150": "群公告字數超出限制，最大長度為150",
};

export default TUIGroup;
